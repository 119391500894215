import { Card, CardContent } from '@mui/material'
import React from 'react'
import BackLink from '../../shared/BackLink'
import FileUploader from '../../shared/FileUploader'

export default function TelkomADLImport() {
    
    const getFile = (f) => {
        console.log(f)
        
    }

    return (
        <div>
            <h1>Telkom PRE ADL Import</h1>
            <BackLink />
            <Card>
                <CardContent>
                    <FileUploader
                        sendFile={getFile}
                        label="Upload PRE ADL File"
                        storagePath={"telkom_adl/"}
                    />
                </CardContent>
            </Card>
        </div>
    )
}
