import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import SimDataGrid from "../../shared/data-grid/SimDataGrid";
import { Button } from "@mui/material";

export default function LookerReportList() {
  const { GetLookerReports } = useDb();

  const reports = GetLookerReports();

  const rows =
    reports &&
    reports.map((report) => ({
      ...report,
    }));

  const columns = [
    {
      field: "title",
      headerName: "Report Name",
      width: 700,
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            size="small"
            href={params.row.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row.title}
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      <SimDataGrid data={rows} columns={columns} />
    </div>
  );
}
