import React, { useState } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import logo from "../../img/shekinah.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PhoneVerify() {
  const classes = useStyles();
  const { login, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(email, password);
      history.push("/");
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} width={200} />
        <br />
        <Typography component="h1" variant="h5">
          Enter OTP
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Enter OTP"
          name="otp"
          autoFocus
          type="number"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <span style={{ color: "red" }}>{error}</span>
        <br />
        <span style={{ color: "blue" }}>{currentUser?.email}</span>
        <Button
          type="submit"
          disabled={loading}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Submit
        </Button>
        <Grid container>
          <Grid item xs>
            <Link to={"/phone-login"} variant="body2">
              {"< Back"}
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
