export const customers = {
  chadez: {
    ricaUrl: "https://us-central1-chadez-dbc8e.cloudfunctions.net/rica",
    logo: "https://firebasestorage.googleapis.com/v0/b/chadez-dbc8e.appspot.com/o/chadez.png?alt=media&token=aa8b7fcf-732e-4f59-91de-3ba910a74d90",
    theme: {
      palette: {
        primary: {
          main: "#126289",
          contrastText: "#fff",
        },
      },
    },
  },
  afrah: {
    ricaUrl: "https://us-central1-afrahsims.cloudfunctions.net/rica",
    logo: "https://firebasestorage.googleapis.com/v0/b/afrahsims.appspot.com/o/afrah.png?alt=media&token=32232e6f-2ef8-44b9-917e-9ee3d96357ec",
    theme: {
      palette: {
        primary: {
          main: "#102b56",
          contrastText: "#fff",
        },
      },
    },
  },
  shekinah: {
    ricaUrl: "https://us-central1-shekinah-sim.cloudfunctions.net/rica",
    logo: "https://firebasestorage.googleapis.com/v0/b/shekinah-sim.appspot.com/o/shekinah.png?alt=media&token=b0e02883-8483-4018-8f04-28034d60b202",
    theme: {
      palette: {
        primary: {
          main: "#fd510b",
          contrastText: "#fff",
        },
      },
    },
  },
  dev: {
    ricaUrl: "https://us-central1-simboss-dev.cloudfunctions.net/rica",
    logo: "https://firebasestorage.googleapis.com/v0/b/simboss-dev.appspot.com/o/jpLong.png?alt=media&token=fa0b1031-c3a2-4c7e-90e5-7293ba47e39a",
    theme: {
      palette: {
        primary: {
          main: "#a53922",
          contrastText: "#fff",
        },
      },
    },
  },
  default: {
    ricaUrl: "NA",
    logo: "https://firebasestorage.googleapis.com/v0/b/simboss-dev.appspot.com/o/SIM.png?alt=media&token=ba72635f-7f6f-4925-8c62-4965c4182811",
    theme: {
      palette: {
        primary: {
          main: "#47b9e3",
          contrastText: "#fff",
        },
      },
    },
  },
};
