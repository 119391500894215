import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material'

export default function AllUsers() {
    const { GetAllUsers } = useDb()
    const users = GetAllUsers()

    return (
        <div>
            <CSVLink
                data={users}
                style={{ marginTop: 5, textDecoration: 'none'}}
                filename={`Users`}
            >
                <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFileExcel}/>} style={{ backgroundColor: 'green', color: '#fff'}}> Export to CSV</Button>
            </CSVLink>
        </div>
    )
}
