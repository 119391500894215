import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import allocateStock from "../../img/allocate-stock.svg";

export default function AllocateStock() {
  const { currentUser } = useAuth();
  return (
    <div>
      <h1>Allocate Stock</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={"Allocate Stock"}
              avatar={<FontAwesomeIcon icon={faTruck} />}
            />
            <CardContent className="sim-card-content">
              <img src={allocateStock} width="100%" height="100" />
            </CardContent>
            <CardActions>
              <Link to={"/allocate-stock/box/boxes"}>
                <Button color="primary">Box</Button>
              </Link>
              <Link to={"/allocate-stock/box/bricks"}>
                <Button color="primary">Brick</Button>
              </Link>
              {currentUser.features?.boxScanning && (
                <Link to={"/allocate-barcode"}>
                  <Button color="primary">2D BarCode</Button>
                </Link>
              )}
              {currentUser.features?.boxScanning && (
                <Link to={"/allocate-singles"}>
                  <Button color="primary">Single</Button>
                </Link>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
