import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import UsersTable from './UsersTable'

export default function UserSettings() {
    // const { GetAllUsers } = useDb()
    // const users = GetAllUsers()
    return (
        <>
            <h1>User Settings</h1>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    {/* <UsersTable users={users} /> */}
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
