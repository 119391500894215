import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import importStock from "../../img/import-stock.svg";

export default function ImportStock() {
  return (
    <div>
      <Card>
        <CardHeader
          title={"Import Stock"}
          avatar={<FontAwesomeIcon icon={faFileImport} />}
        />
        <CardContent className="sim-card-content">
          <img src={importStock} width="100%" height="100" />
        </CardContent>
        <CardActions>
          <Link to="/import-stock/vodacom">
            <Button color="primary">Vodacom</Button>
          </Link>
          <Link to="/import-stock/mtn">
            <Button color="primary">MTN</Button>
          </Link>
          <Link to="/import-stock/telkom">
            <Button color="primary">Telkom</Button>
          </Link>
          <Link to="/import-stock/cellc">
            <Button color="primary">Cell C</Button>
          </Link>
          <Link to="/import-stock/hello">
            <Button color="primary">Hello Mobile</Button>
          </Link>
          <Link to="/import-stock/dash">
            <Button color="primary">Dash Mobile</Button>
          </Link>
          <Link to="/import-stock/megatel">
            <Button color="primary">Megatel</Button>
          </Link>
          {/* <Button>CellC</Button>
                    <Button>Telkom</Button> */}
        </CardActions>
      </Card>
    </div>
  );
}
