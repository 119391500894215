import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export default function TwoDPhoneScan({ open, close, send, simArray }) {
  const handleClose = () => {
    close();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={(err, results) => {
            if (results && results.text) {
              send(results.text);
              handleClose();
            };
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
