import { Button, Card, CardActions } from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../contexts/DatabaseContext";
import BackLink from "../shared/BackLink";
import SimDataGrid from "../shared/data-grid/SimDataGrid";
import AddSupplier from "./AddSupplier";

export default function Suppliers() {
  const { GetSuppliers } = useDb();
  const [addOpen, setAddOpen] = useState(false);

  const suppliers = GetSuppliers();

  const rows =
    suppliers &&
    suppliers.map((s) => ({
      ...s,
    }));

  const columns = [
    { field: "supplierName", headerName: "Supplier Name", width: 500 },
  ];

  return (
    <div>
      <h1>Suppliers</h1>
      <BackLink />
      <Card>
        <CardActions>
          <Button
            onClick={() => setAddOpen(true)}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Add Supplier
          </Button>
        </CardActions>
      </Card>
      <br/>
      <SimDataGrid data={rows} columns={columns} />

      <AddSupplier open={addOpen} close={() => setAddOpen(false)} />
    </div>
  );
}
