import {
  Button,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { SimCard } from "@mui/icons-material";
import Select from "react-select";
import {
  selectFieldProps,
  textFieldProps,
  idTypes,
  countryCodes,
  rica_networks,
} from "../../shared/constants";
import { useDb } from "../../../contexts/DatabaseContext";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { customers } from "../../../config/customerConfig";

export default function RicaForm() {
  const { getRicaSettings } = useDb();
  const { currentUser } = useAuth();

  console.log(currentUser.uid);
  console.log(currentUser.companyId);

  // Form State
  const [state, setState] = useState({});
  const [network, setNetwork] = useState(null);
  const [proofofaddress, setProofofResidence] = useState(true);
  const [countryCodeDial, setCountryCodeDial] = useState("012");
  const [areaCodeDial, setAreaCodeDial] = useState("012");
  const [referenceType, setreferenceType] = useState("SIM");
  const [dialingNumber, setdialingNumber] = useState("0000000000");
  const [ricaSettings, setricaSettings] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [result_message, setResultMessage] = useState("Loading...");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const firstLoad = async () => {
    const ricaSettings = await getRicaSettings();
    setricaSettings(ricaSettings);
    setPageLoaded(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  //Submit Variables
  const handleSubmit = (e) => {
    e.preventDefault();

    setResultMessage("Loading...");

    console.log(ricaSettings);
    const last4SIM = state.referenceNumber.slice(-4);
    const groupName = ricaSettings.groupName;
    const password = ricaSettings.password;
    const Agent = ricaSettings.Agent;

    const info = {
      ...state,
      last4SIM,
      proofofaddress,
      areaCodeDial,
      countryCodeDial,
      network,
      referenceType,
      dialingNumber,
      groupName,
      password,
      Agent,
      uid: currentUser.uid,
    };

    const ricaUrl = customers[currentUser.alias].ricaUrl;

    fetch(ricaUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    })
      .then((response) => response.json())
      .then((data) => setResultMessage(data.result), setConfirmOpen(true));
  };

  if (!pageLoaded) {
    firstLoad();
    return <CircularProgress />;
  } else
    return (
      <div>
        <h1>RICA SIM Card</h1>
        <form>
          <Select
            options={rica_networks}
            {...selectFieldProps}
            placeholder="Select Network ..."
            onChange={(v) => setNetwork(v.value)}
            required
          />
          <br />
          <TextField
            {...textFieldProps}
            required
            label={"SIM Number (ICCID)"}
            onChange={(e) =>
              setState({ ...state, referenceNumber: e.target.value })
            }
          />

          <Select
            options={idTypes}
            {...selectFieldProps}
            onChange={(v) => setState({ ...state, IDType: v.value })}
            placeholder="ID Type"
            required
          />
          <br />
          <Select
            options={countryCodes}
            {...selectFieldProps}
            onChange={(v) => setState({ ...state, countryCode: v.value })}
            placeholder="ID Nationality"
            required
          />
          <br />
          <TextField
            {...textFieldProps}
            required
            label={"ID Number"}
            onChange={(e) => setState({ ...state, IDnr: e.target.value })}
          />
          <TextField
            {...textFieldProps}
            required
            label={"Name"}
            onChange={(e) => setState({ ...state, fullName: e.target.value })}
          />
          <TextField
            {...textFieldProps}
            required
            label={"Surname"}
            onChange={(e) => setState({ ...state, surName: e.target.value })}
          />

          <b />
          <b />
          <h3>Residental Street Address (Individual)</h3>

          <TextField
            {...textFieldProps}
            required
            label={"Address Line 1"}
            onChange={(e) => setState({ ...state, address1: e.target.value })}
          />
          <TextField
            {...textFieldProps}
            label={"Address Line 2"}
            onChange={(e) => setState({ ...state, address2: e.target.value })}
          />
          <TextField
            {...textFieldProps}
            label={"Address Line 3"}
            onChange={(e) => setState({ ...state, address3: e.target.value })}
          />
          <TextField
            {...textFieldProps}
            required
            label={"City / Town"}
            onChange={(e) => setState({ ...state, cityTown: e.target.value })}
          />
          <TextField
            {...textFieldProps}
            required
            label={"Suburb"}
            onChange={(e) => setState({ ...state, suburb: e.target.value })}
          />
          <TextField
            {...textFieldProps}
            required
            label={"Postal Code"}
            onChange={(e) => setState({ ...state, postalcode: e.target.value })}
          />

          <Select
            options={countryCodes}
            {...selectFieldProps}
            onChange={(v) =>
              setState({ ...state, residentialCountry: v.value })
            }
            placeholder=" Residential Country"
            required
          />
          <br />
          <br />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            startIcon={<SimCard />}
            onClick={handleSubmit}
          >
            Submit RICA
          </Button>
        </form>
        <Dialog
          open={confirmOpen}
          maxWidth="sm"
          fullWidth
          onClose={handleConfirmClose}
        >
          <DialogContent>
            <Typography>{result_message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              onClick={handleConfirmClose}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}
