import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import BackLink from "../../../shared/BackLink";
import DealerLevelActivations from "./activations/DealerLevelActivations";
import SuperActivations from "./activations/SuperActivations";

export default function ActivationsD() {
  const { currentUser } = useAuth();

  const reportStack = {
    2: <SuperActivations uid={currentUser.uid} />,
    3: <SuperActivations uid={currentUser.uid} />,
    4: <DealerLevelActivations uid={currentUser.uid} />,
  };

  return (
    <div>
      <h1>MTN Activations</h1>
      <BackLink />
      {reportStack[currentUser.rank]}
    </div>
  );
}
