import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Avatar, Button, Card, CardHeader, Typography } from '@mui/material'
import { ArrowBack, Info, PhoneAndroid } from '@mui/icons-material'
import ReportTree from '../ReportTree'
import { useDb } from '../../../contexts/DatabaseContext'

export default function ConnectionReportSummary() {
    const location = useLocation()
    const { report, total, network, month, year } = location.data
    const history = useHistory()
    const { publishReportToAgents } = useDb()

    const [state, setState] = React.useState({
        loading: false,
        error: null
    })

    let rows = []
    report.map(r => {
        return rows.push({
            ...r,
            id: r.dealerID
        })
    })

    function list_to_tree(list) {
        var map = {}, node, roots = [], i;
        
        for (i = 0; i < list.length; i += 1) {
          map[list[i].id] = i; // initialize the map
          list[i].children = []; // initialize the children
        }
        
        for (i = 0; i < list.length; i += 1) {
          node = list[i];
          if (node.level !== "2") {
            // if you have dangling branches check that map[node.parentId] exists
            if(list[map[node.parentDealer]]) {
                list[map[node.parentDealer]].children.push(node);
            }
            else {
                roots.push(node)
            }
          } else {
            roots.push(node);
          }
        }
        return roots;
    }
    const tree = list_to_tree(rows)

    const handlePublish = async () => {
        setState({
            loading: true,
            error: null
        })
        try {
            await publishReportToAgents(rows, month, year, network, 'connections')
            setState({
                loading: false,
                error: null,
                message: 'Reports Sent!'
            })
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <h1>Report Summary</h1>
            <Button onClick={() => history.goBack()} color="primary" startIcon={<ArrowBack />}>Back</Button>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <Info />
                        </Avatar>
                    }
                    title="Please Note"
                    subheader="Daily Connections will only show allocated connections. Total Connections will only show after you've imported Final Monthly Connections."
                />
            </Card>
            <br />
            <ReportTree report={tree} type={'Connections'} total={total} />
            <br/>
            <Button disabled={state.loading} onClick={handlePublish} color="primary" variant="contained" startIcon={<PhoneAndroid />}>Publish Results</Button>
            {
                state.message && <Typography>{state.message}</Typography>
            }
            {
                state.error && <Typography color="secondary">{state.error}</Typography>
            }
        </div>
    )
}
