import { Card, CardContent } from '@mui/material'
import React, { useState } from 'react'
import BackLink from '../../shared/BackLink'
import FileUploader from '../../shared/FileUploader'
import Select from 'react-select'
import { months, selectFieldProps, years } from '../../shared/constants'

export default function MtnBaseImport() {
    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    
    
    const getFile = (f) => {
        console.log(f)
        
    }

    return (
        <div>
            <h1>MTN base Import</h1>
            <BackLink />
            <Card>
                <CardContent>
                    <Select 
                        options={years}
                        {...selectFieldProps}
                        onChange={v => setYear(v.value)}
                        placeholder="Select Year ... "
                    />
                    <br/>
                    <Select 
                        options={months}
                        {...selectFieldProps}
                        onChange={v => setMonth(v.value)}
                        placeholder="Select Month ... "
                    />
                    {
                        year && month && <FileUploader
                            sendFile={getFile}
                            label="Upload base File"
                            storagePath={`mtn_base/${year}/${month}`}
                        />
                    }
                    
                </CardContent>
            </Card>
        </div>
    )
}
