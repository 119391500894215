import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import SimTile from './SimTile'

export default function SimsTable({ data }) {

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(data.length / itemsPerPage)
    );
    
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ICCID</TableCell>
                            <TableCell>Connected</TableCell>
                            <TableCell>Activated</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data
                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                            .map(sim => {
                                return <SimTile sim={sim} key={sim.id} />
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <br/>
            <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                page={page}
                onChange={(e, val) => setPage(val)}
                defaultPage={1}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
            /> 
        </>
    )
}
