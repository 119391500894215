import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const fbConfig = JSON.parse(`{"apiKey":"AIzaSyBoHEktHz5IUOhkATXmgEGOuLZJb7Z1tyA","authDomain":"afrahsims.firebaseapp.com","projectId":"afrahsims","storageBucket":"afrahsims.appspot.com","messagingSenderId":"253551783846","appId":"1:253551783846:web:faffaf601c8fcf2e34a967"}`);
const app = firebase.initializeApp(fbConfig);
export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const fieldValue = firebase.firestore.FieldValue;
export const authFunc = firebase.auth;
export const functions = app.functions("us-central1");
export default app;
