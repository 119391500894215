import React from "react";
import BackLink from "../../shared/BackLink";

export default function MTNDailyUsage() {
  return (
    <div>
      <h1>MTN Daily Usage & Connections</h1>
      <BackLink />
      <iframe
        width="100%"
        height="1200"
        src="https://datastudio.google.com/embed/reporting/f838fe1b-99af-438e-9e6a-bae20d370b81/page/I5mrC"
        frameBorder="0"
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
}
