import React from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import BackLink from '../../../shared/BackLink'
import DealerLevelDailyConnections from './DealerLevelDailyConnections'
import SuperDailyConnections from './SuperDailyConnections'

export default function DailyConnectionsD() {
    const { currentUser } = useAuth()

    const reportStack = {
        2: <SuperDailyConnections uid={currentUser.uid} />,
        3: <SuperDailyConnections uid={currentUser.uid} />,
        4: <DealerLevelDailyConnections uid={currentUser.uid} />
    }

    console.log(currentUser.rank)
    return (
        <div>
            <h1>MTN Daily Connections</h1>
            <BackLink />
            { reportStack[currentUser.rank] }
        </div>
    )
}
