import React, { useState} from 'react'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Snackbar, TextField } from '@mui/material'
import { CreditCard, Save } from '@mui/icons-material'
import { textFieldProps } from '../shared/constants'
import { useDb } from '../../contexts/DatabaseContext'

export default function ManageCard({ dealer }) {
    const { updateCard } = useDb()

    const [state, setState] = useState({
        ...dealer
    })

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false)

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await updateCard(dealer.id, { 
                cardExpiry: state.cardExpiry,
                cardLastFour: state.cardLastFour
            }).then(() => {
                setSnackOpen(true)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <Card>
                <CardHeader
                    title={'Update Card Details'}
                    subheader="Existing Card Details will not be displayed"
                    avatar={<Avatar><CreditCard /></Avatar>}
                />
                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <TextField
                            {...textFieldProps}
                            label="Last 4 Digits of Card"
                            type="number"
                            name="cardLastFour"
                            value={state.cardLastFour ? state.cardLastFour : ''}
                            required
                            onChange={handleChange}
                        />
                        <TextField
                            {...textFieldProps}
                            label="Expires"
                            name="cardExpiry"
                            value={state.cardExpiry ? state.cardExpiry : ''}
                            required
                            onChange={handleChange}
                        />
                    </CardContent>
                    <CardActions>
                        <Button disabled={ loading } startIcon={<Save />} fullWidth type="submit" color="primary" variant="contained">Update Card</Button>
                    </CardActions>
                </form>
            </Card>
            
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackOpen}
                autoHideDuration={4000}
                onClose={() => setSnackOpen(false)}
                message="Card Details Updated!"
            />
        </div>
    )
}
