import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { textFieldProps } from '../../shared/constants'
import { functions } from '../../../firebase'

export default function EditEmail({ open, close, dealer }) {
    
    const [email, setEmail] = useState(dealer.email ? dealer.email : '')
    const [pw, setPw] = useState('')
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setEmail('')
        setPw('')
        setLoading(false)
        close()
    }

    const handleSubmit = async () => {
        setLoading(true)
        const functionsRef = functions.httpsCallable('changeUserAccessFromFrontEnd');
        const result = await functionsRef({ email, pw, uid: dealer.id })
        console.log(result)
        handleClose()
    }

    useEffect(() => {
        setEmail(dealer.email)
        setPw('')
    }, [open])
    


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Edit Email & PW
            </DialogTitle>
            <DialogContent>
                <TextField
                    type="email"
                    label="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    {...textFieldProps}
                />
                <TextField
                    type="password"
                    label="Password"
                    value={pw}
                    onChange={e => setPw(e.target.value)}
                    {...textFieldProps}
                />
            </DialogContent>
            <DialogActions>
                {
                    loading ? <CircularProgress /> :
                    <div>
                        <Button color="secondary" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Confirm</Button>
                    </div>
                }
                

            </DialogActions>
        </Dialog>
    )
}
