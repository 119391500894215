import { Card, CardContent } from "@mui/material";
import React, { useEffect } from "react";
import BackLink from "../../shared/BackLink";
import FileUploader from "../../shared/FileUploader";
import { useParams } from "react-router";

export default function VodacomImports() {
  const { type } = useParams();
  const formattedType = type.toUpperCase();

  useEffect(() => {
    console.log(type, "TYPE");
  }, []);

  const getFile = f => {
    console.log(f);
  };

  return (
    <div>
      <h1>Vodacom Imports</h1>
      <BackLink />
      <Card>
        <CardContent>
          <FileUploader
            sendFile={getFile}
            label={`Upload Vodacom ${formattedType} File`}
            storagePath={`vodacom_${type}/`}
          />
        </CardContent>
      </Card>
    </div>
  );
}
