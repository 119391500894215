import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../contexts/DatabaseContext";
import { textFieldProps } from "../shared/constants";

export default function AddSupplier({ open, close }) {
  const { createSupplier } = useDb();
  const [supplierName, setSupplierName] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setSupplierName(null);
    setLoading(false);
    close();
  };

  const handleChange = (e) => {
    setSupplierName(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createSupplier({
        supplierName
      });
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Supplier</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          onChange={handleChange}
          label="Supplier Name"
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
