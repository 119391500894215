import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { Button, LinearProgress } from '@mui/material'
import { useDb } from '../../../contexts/DatabaseContext'
import { useParams } from 'react-router-dom'

export default function ActivationsRaw() {
    const { network, month, year } = useParams()
    const { activationReportAdminRaw } = useDb()

    // Action State
    const [loaded, setLoaded] = useState(false)
    const [sims, setSims] = useState(null)

    const firstLoad = async () => {
        const s = await activationReportAdminRaw(network, year, month)
        setSims(s)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return (
        <div>
            <CSVLink
                data={sims}
                style={{ marginTop: 5, textDecoration: 'none'}}
                filename={`Sims`}
            >
                <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFileExcel}/>} style={{ backgroundColor: 'green', color: '#fff'}}> Export to CSV</Button>
            </CSVLink>
        </div>
    )
}
