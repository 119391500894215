import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import BackLink from "../../shared/BackLink";
import { textFieldProps } from "../../shared/constants";
import ConfirmBarcode from "./ConfirmBarcode";
import Select from "react-select";
import { PhoneAndroid } from "@mui/icons-material";
import TwoDPhoneScan from "./TwoDPhoneScan";

export default function AllocateBarcode() {
  const { userLevel } = useAuth();
  const { SubGetChildDealers, GetAllDealers } = useDb();
  const [dealer, setDealer] = useState();
  const [phoneScan, setPhoneScan] = useState(false);

  const dealers = userLevel <= 1 ? GetAllDealers() : SubGetChildDealers();

  const dealerOptions = dealers.map((d) => ({
    value: d,
    label: `${d.contactName} ${d.contactSurname} - (Level ${d.rank})`,
  }));

  const [sims, setSims] = useState();
  const [simString, setSimString] = useState();
  const [simArray, setSimArray] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handlePhoneData = (data) => {
    console.log(data);
    setSims(data);
    phoneSubmit();
  };

  const phoneSubmit = async () => {
    await handleSubmit();
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    const splitSims = sims.split(",");
    console.log(splitSims);
    if (splitSims.length !== 20) {
      window.alert("Error: That doesn't look like a box of 20 vodacom SIMS.");
      return;
    }
    setSimString(sims.toString());
    setSimArray(splitSims);
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
    setSims("");
    setSimString(null);
    setSimArray([]);
  };

  return (
    <div>
      <h1>Allocate Vodacom 2D Barcode</h1>
      <BackLink />
      <Select
        options={dealerOptions}
        onChange={(val) => setDealer(val.value)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        placeholder={"Select a Dealer"}
        className="sim-select"
      />
      {dealer && (
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                {...textFieldProps}
                label="Scan Vodacom 2D Barcode"
                autoFocus
                onChange={(e) => setSims(e.target.value)}
                value={sims}
              />
            </form>
          </CardContent>
          {/* <CardActions style={{ float: "right" }}>
            Don't have 2D Scanner? &nbsp;{" "}
            <Button
              size="small"
              variant="outlined"
              startIcon={<PhoneAndroid />}
              onClick={() => setPhoneScan(true)}
            >
              Use Phone Camera
            </Button>
          </CardActions> */}
        </Card>
      )}

      {dealer && simString && (
        <ConfirmBarcode
          open={confirmOpen}
          close={handleClose}
          simArray={simArray}
          simString={simString}
          dealer={dealer}
        />
      )}

      <TwoDPhoneScan
        open={phoneScan}
        close={() => setPhoneScan(false)}
        send={handlePhoneData}
      />
    </div>
  );
}
