import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React from 'react'
import { useHistory } from 'react-router-dom'

export default function BackLink() {
    const history = useHistory()

    return (
        <Button
            style={{ marginBottom: 10 }}
            color="primary"
            startIcon={<ArrowBack />}
            onClick={() => history.goBack()}
        >
            Back
        </Button>
    )
}
