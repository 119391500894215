import AdminDashboard from "../admin-dashboard/AdminDashboard";
import AllocateStock from "../allocate-stock/AllocateStock";
import AllocateBarcode from "../allocate-stock/barcode/AllocateBarcode";
import AllocateBox from "../allocate-stock/box/AllocateBox";
import AllocateInvoice from "../allocate-stock/invoice/AllocateInvoice";
import AllocateSingleSims from "../allocate-stock/sim/AllocateSingleSims";
import CheckStockDS from "../check-stock/datastudio/CheckStockDS";
import AllUsers from "../dealercomps/reports/AllUsers";
import DealerEfficiency from "../dealercomps/reports/DealerEfficiency";
import DrilldownSims from "../dealercomps/reports/DrilldownSims";
import ImportStock from "../import-stock/ImportStock";
import ImportMtn from "../import-stock/mtn/ImportMtn";
import ImportTelkom from "../import-stock/telkom/ImportTelkom";
import ImportVodacom from "../import-stock/vodacom/ImportVodacom";
import IwanConsole from "../iwan/IwanConsole";
import RCheckDealerStock from "../manage-dealers/check-stock-r/RCheckDealerStock";
import AgentsEfficiency from "../manage-dealers/dealer-detail-r/AgentsEfficiency";
import DealerAgents from "../manage-dealers/dealer-detail-r/DealerAgents";
import RDealerDetail from "../manage-dealers/dealer-detail-r/RDealerDetail";
import DealerStock from "../manage-dealers/DealerStock";
import ManageDealers from "../manage-dealers/ManageDealers";
import ImportActivations from "../network-imports/activations/ImportActivations";
import DailyConnectionsImport from "../network-imports/connections/DailyConnectionsImport";
import ImportConnections from "../network-imports/connections/ImportConnections";
import KCMobileImports from "../network-imports/kc-mobile/KCMobileImports";
import MtnBaseImport from "../network-imports/mtnraw/MtnBaseImport";
import MtnChurnImport from "../network-imports/mtnraw/MtnChurnImport";
import MtnSimCiConImport from "../network-imports/mtnraw/MtnSimCiConImport";
import MtnSimCiImport from "../network-imports/mtnraw/MtnSimCiImport";
import MtnUsageImport from "../network-imports/mtnraw/MtnUsageImport";
import MtnSimSwapsImport from "../network-imports/mtnraw/MtnSimSwapsImport";
import VodacomImports from "../network-imports/vodacom/VodacomImports";
import NetworkImports from "../network-imports/NetworkImports";
import OGRImporter from "../network-imports/ogr/OGRImporter";
import TelkomADLImport from "../network-imports/telkomraw/TelkomADLImport";
import TelkomONGImport from "../network-imports/telkomraw/TelkomONGImport";
import NetworkImportVC from "../network-imports/vodacom/NetworkImportVC";
import AllNotifications from "../notifications/AllNotifications";
import AllocationDetail from "../notifications/AllocationDetail";
import ActivationReportSummary from "../reports/activations/ActivationReportSummary";
import ActivationsRaw from "../reports/activations/ActivationsRaw";
import AdminDrilldown from "../reports/AdminDrilldown";
import ConnectionReportSummary from "../reports/connections/ConnectionReportSummary";
import MTNDailyUsage from "../reports/datastudio-reports/MTNDailyUsage";
import MTNMonthlyCommission from "../reports/datastudio-reports/MTNMonthlyCommission";
import JsonDealerReport from "../reports/JsonDealerReport";
import JsonReport from "../reports/JsonReport";
import Reports from "../reports/Reports";
import LevelSettings from "../settings/levels/LevelSettings";
import RicaForm from "../settings/rica/RicaForm";
import RicaSettings from "../settings/rica/RicaSettings";
import Settings from "../settings/Settings";
import UserSettings from "../settings/users/UserSettings";
import SimDetail from "../sim-results/SimDetail";
import Suppliers from "../suppliers/Suppliers";
import PrivateRoute from "./PrivateRoute";
import TelkomSmartCallMonthly from "../network-imports/telkomraw/TelkomSmartCallMonthly";
import ImportHello from "../import-stock/hello/ImportHello";
import ImportDash from "../import-stock/dash/ImportDash";
import ImportMegatel from "../import-stock/megatel/ImportMegatel";
import ImportCellC from "../import-stock/cellc/ImportCellC";

export default function Routes() {
  return (
    <>
      <PrivateRoute exact path="/" component={AdminDashboard} />
      <PrivateRoute exact path="/allocate-stock" component={AllocateStock} />
      <PrivateRoute exact path="/import-stock" component={ImportStock} />
      <PrivateRoute path="/import-stock/vodacom" component={ImportVodacom} />
      <PrivateRoute path="/import-stock/mtn" component={ImportMtn} />
      <PrivateRoute path="/import-stock/telkom" component={ImportTelkom} />
      <PrivateRoute exact path="/manage-dealers" component={ManageDealers} />
      <PrivateRoute path="/manage-dealers/stock/:id" component={DealerStock} />
      <PrivateRoute exact path="/reports" component={Reports} />
      <PrivateRoute exact path="/network-imports" component={NetworkImports} />
      <PrivateRoute
        path="/network-imports/vodacom"
        component={NetworkImportVC}
      />
      <PrivateRoute
        path="/allocate-stock/box/:boxType"
        component={AllocateBox}
      />
      <PrivateRoute
        path="/import-reports/activations"
        component={ImportActivations}
      />
      <PrivateRoute
        path="/import-reports/connections"
        component={ImportConnections}
      />
      <PrivateRoute
        path="/import-reports/daily-connections"
        component={DailyConnectionsImport}
      />
      <PrivateRoute
        path="/allocate-stock/invoice"
        component={AllocateInvoice}
      />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute path="/settings/levels" component={LevelSettings} />
      <PrivateRoute path="/settings/users" component={UserSettings} />
      <PrivateRoute path="/settings/rica" component={RicaSettings} />
      <PrivateRoute path="/rica" component={RicaForm} />
      <PrivateRoute
        path="/efficiency/:dealerId/:rank"
        component={DealerEfficiency}
      />
      <PrivateRoute path="/import-ogr" component={OGRImporter} />
      <PrivateRoute
        path="/drilldown-sims/:network/:dealerId/:year/:month/:state/:type"
        component={DrilldownSims}
      />
      <PrivateRoute path="/sim/:network/:iccid" component={SimDetail} />
      <PrivateRoute
        exact
        path="/dd-report-admin/:type/:rank/:dealerId/:year/:month/:network"
        component={AdminDrilldown}
      />
      <PrivateRoute exact path="/all-users" component={AllUsers} />
      <PrivateRoute
        exact
        path="/activations-raw/:network/:year/:month"
        component={ActivationsRaw}
      />
      <PrivateRoute exact path="/iwan-console" component={IwanConsole} />
      <PrivateRoute
        exact
        path="/json-report/:network/:type/:reportId/:month/:year"
        component={JsonReport}
      />
      <PrivateRoute
        exact
        path="/json-dealer-report/:network/:type/:reportId/:dealerId/:month/:year"
        component={JsonDealerReport}
      />

      {/* Notifications */}
      <PrivateRoute
        exact
        path="/all-notifications"
        component={AllNotifications}
      />
      <PrivateRoute
        exact
        path="/allocation-detail/:allocationId"
        component={AllocationDetail}
      />

      {/* New BQ Method */}
      <PrivateRoute
        exact
        path="/activation-report-summary"
        component={ActivationReportSummary}
      />
      <PrivateRoute
        exact
        path="/connection-report-summary"
        component={ConnectionReportSummary}
      />
      <PrivateRoute
        path="/check-dealer-stock/:dealerId"
        component={RCheckDealerStock}
      />
      <PrivateRoute path="/dealer-detail/:dealerId" component={RDealerDetail} />
      <PrivateRoute path="/check-stock/" component={CheckStockDS} />

      {/* Agents Efficiency */}
      <PrivateRoute
        path="/agents-efficiency/:parentDealer/:network"
        component={AgentsEfficiency}
      />
      <PrivateRoute path="/dealer-agents/:dealerId" component={DealerAgents} />

      {/* Datastudio Report */}
      <PrivateRoute path="/mtn-daily-report" component={MTNDailyUsage} />
      <PrivateRoute
        path="/mtn-monthly-report"
        component={MTNMonthlyCommission}
      />

      {/* Datastudio Report */}
      <PrivateRoute path="/mtn-daily-report" component={MTNDailyUsage} />
      <PrivateRoute
        path="/mtn-monthly-report"
        component={MTNMonthlyCommission}
      />
      {/* <PrivateRoute
        path="/telkom-monthly-report"
        component={TelkomMonthlyCommission}
      /> */}
      {/* Raw Data Imports */}
      <PrivateRoute path="/mtn-simci-import" component={MtnSimCiImport} />
      <PrivateRoute path="/mtn-simcicon-import" component={MtnSimCiConImport} />
      <PrivateRoute path="/mtn-churn-import" component={MtnChurnImport} />
      <PrivateRoute path="/mtn-base-import" component={MtnBaseImport} />
      <PrivateRoute path="/mtn-usage-import" component={MtnUsageImport} />
      <PrivateRoute
        path="/mtn-sim-swaps-import"
        component={MtnSimSwapsImport}
      />
      <PrivateRoute path="/telkom-adl-import" component={TelkomADLImport} />
      <PrivateRoute path="/telkom-ong-import" component={TelkomONGImport} />
      <PrivateRoute path="/kc-imports" component={KCMobileImports} />
      <PrivateRoute path="/kc-connections" component={KCMobileImports} />
      <PrivateRoute path="/kc-activations" component={KCMobileImports} />
      <PrivateRoute path="/kc-recharges" component={KCMobileImports} />
      <PrivateRoute path="/kc-churn" component={KCMobileImports} />
      <PrivateRoute path="/kc-allocations" component={KCMobileImports} />
      <PrivateRoute path="/vodacom-imports/:type" component={VodacomImports} />

      {/* New 2023 Shekinah */}
      <PrivateRoute path="/suppliers" component={Suppliers} />
      <PrivateRoute path="/allocate-barcode" component={AllocateBarcode} />
      <PrivateRoute path="/allocate-singles" component={AllocateSingleSims} />
      <PrivateRoute
        path="/smartcall-telkom-monthly"
        component={TelkomSmartCallMonthly}
      />
      <PrivateRoute path="/import-stock/hello" component={ImportHello} />
      <PrivateRoute path="/import-stock/dash" component={ImportDash} />
      <PrivateRoute path="/import-stock/megatel" component={ImportMegatel} />
      <PrivateRoute path="/import-stock/cellc" component={ImportCellC} />
    </>
  );
}
