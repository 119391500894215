import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import { CreditCard, Edit, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useDb } from "../../contexts/DatabaseContext";
import SimDataGrid from "../shared/data-grid/SimDataGrid";
import EditDealer from "./EditDealer";
import ManageCard from "./ManageCard";

export default function DealerList({ dealerLevel }) {
  const { GetDealerList } = useDb();
  const { currentUser } = useAuth();
  const dealers = GetDealerList(dealerLevel);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [widget, setWidget] = useState(null);
  const [search, setSearch] = useState(null);

  // Pagination
  const itemsPerPage = 10; // <- Not state but related to below pagination
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(dealers.length / itemsPerPage));

  const openCardDialog = (d) => {
    setWidget(<ManageCard dealer={d} closeDialog={closeDialog} />);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setTimeout(500);
    setWidget(null);
  };

  // Edits
  const [editOpen, setEditOpen] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState(false);
  const handleEdit = (d) => {
    setSelectedDealer(d);
    setEditOpen(true);
  };

  const rows =
    dealers &&
    dealers.map((d) => ({
      id: d.id,
      dealerName: d.dealerName,
      email: d.email,
      phone: d.phone,
      city: d.city,
      province: d.province,
      dealerId: d.contactIdNo,
      contactName: d.contactName,
      contactSurname: d.contactSurname,
      suburb: d.suburb,
      contactIdNo: d.contactIdNo,
      street: d.street,
    }));

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <ButtonGroup>
              <IconButton color="primary" size="small">
                <Edit onClick={() => handleEdit(params.row)} />
              </IconButton>
              {
                (currentUser.companyId === "kNa6fz4vZad6033vZZtz" && (
                  <IconButton color="primary" size="small">
                    <CreditCard onClick={() => openCardDialog(params.row)} />
                  </IconButton>
                ))
              }
              <Link to={`/dealer-detail/${params.row.id}`}>
                <IconButton color="primary" size="small">
                  <Search />
                </IconButton>
              </Link>
            </ButtonGroup>
          </div>
        );
      },
    },
    { field: "contactName", headerName: "First Name", width: 200 },
    { field: "contactSurname", headerName: "Last Name", width: 200 },
    { field: "dealerName", headerName: "Dealer Trading Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "dealerId", headerName: "ID/Passport", width: 200 },
    { field: "city", headerName: "City", width: 200 },
    { field: "province", headerName: "Province", width: 200 },
  ];

  return (
    <>
      <SimDataGrid data={rows} columns={columns} />
      <br />
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
        <DialogContent>{widget}</DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => closeDialog()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {selectedDealer ? (
        <EditDealer
          open={editOpen}
          closeDialog={() => setEditOpen(false)}
          dealer={selectedDealer}
        />
      ) : null}
    </>
  );
}
