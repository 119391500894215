import React, { useState } from "react";
import { Switch, Link, useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material/";
import clsx from "clsx";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Routes from "./routes/Routes";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faTruck,
  faFileImport,
  faIdCard,
  faChartBar,
  faToolbox,
  faSimCard,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../contexts/AuthContext";
import { useDb } from "../contexts/DatabaseContext";

import { Helmet } from "react-helmet";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  icons: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  menuText: {
    color: "#fff",
    textDecoration: "none",
  },
  subMenuItem: {
    paddingLeft: 55,
  },
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#fff",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function MainMenu({ logo }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { logout, currentUser } = useAuth();
  const history = useHistory();
  const { GetCompanyName } = useDb();
  const coName = GetCompanyName();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = async () => {
    await logout();
    history.push("/login");
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{coName}</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large">
            <MenuIcon />
          </IconButton>
          <img src={logo} style={{ maxHeight: 50 }} />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div style={{ width: "100%", padding: "10px 10px 0 0" }}></div>
          <IconButton style={{ color: "#fff" }} onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  className={classes.icons}
                  icon={faTachometerAlt}
                />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Dashboard
              </ListItemText>
            </ListItem>
          </Link>

          <Link to="/allocate-stock" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faTruck} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Allocate Stock
              </ListItemText>
            </ListItem>
          </Link>

          {currentUser.master_account && (
            <Link to="/import-stock" className="drawer-item">
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    className={classes.icons}
                    icon={faFileImport}
                  />
                </ListItemIcon>
                <ListItemText className={classes.menuText}>
                  Import Stock
                </ListItemText>
              </ListItem>
            </Link>
          )}

          <Link to="/check-stock" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faSearch} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Check Stock
              </ListItemText>
            </ListItem>
          </Link>

          <Link to="/rica" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faSimCard} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>RICA</ListItemText>
            </ListItem>
          </Link>

          <Link to="/manage-dealers" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faIdCard} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Manage Dealers
              </ListItemText>
            </ListItem>
          </Link>

          <Link to="/reports" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faChartBar} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Reports</ListItemText>
            </ListItem>
          </Link>

          <Link to="/settings" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faToolbox} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Settings</ListItemText>
            </ListItem>
          </Link>
        </List>
        <Button onClick={handleLogOut} color={"secondary"}>
          Log Out
        </Button>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Routes />
        </Switch>
      </main>
    </div>
  );
}
