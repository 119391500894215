import React from "react";
import SimCsvImporter from "../../shared/SimCsvImporter";
import MegatelSchema from "./MegatelSchema";

export default function ImportMegatel() {
  return (
    <SimCsvImporter
      network="megatel"
      simId="sim"
      schema={<MegatelSchema />}
      fileType="stock"
    />
  );
}
