import { Hidden } from "@mui/material";
import React from "react";

export default function DealerFinalConnections({ uid }) {
  var params = {
    "ds0.uid": uid,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);

  return (
    <div>
      <Hidden mdUp>
        {/* Phone Report */}
        <iframe
          width="350"
          height="650"
          src={`https://datastudio.google.com/embed/reporting/73a4b893-457a-412c-af24-cc216794523e/page/OjHuC?params=${encodedParams}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
      <Hidden smDown>
        {/* Desktop Report */}
        <iframe
          width="350"
          height="650"
          src={`https://datastudio.google.com/embed/reporting/73a4b893-457a-412c-af24-cc216794523e/page/OjHuC?params=${encodedParams}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
    </div>
  );
}
