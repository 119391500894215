import { Card, CardContent, CardMedia, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { useParams, Link } from 'react-router-dom'
import mtnBox from '../../../img/mtnBox.png'
import mtnBrick from '../../../img/mtnBrick.png'

const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        paddingTop: '70.25%', 
    },
}))

export default function DealerSelectType() {
    const classes = useStyles()
    const { network } = useParams()
    if(network === 'mtn') return (
        <div>
            <h1>What do you want to allocate?</h1>
            <Grid container spacing={3}>
                <Grid item xs={6} md={4}>
                    <Link to={`/allocate-stock/mtn/boxes`}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                image={mtnBox}
                            />
                            <CardContent>
                                Allocate Boxes (240)
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card>
                        <CardMedia
                            className={classes.media}
                            image={mtnBrick}
                        />
                        <CardContent>
                            Allocate Bricks (30)
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
