import React from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import BackLink from "../../../../shared/BackLink";
import AgentTelkomActivations from "./AgentTelkomActivations";
import SubDTelkomActivations from "./SubDTelkomActivations";
import SuperDTelkomActivations from "./SuperDTelkomActivations";

export default function TelkomActivationsD() {
  const { currentUser } = useAuth();

  const reports = {
    2: <SuperDTelkomActivations uid={currentUser.uid} />,
    3: <SubDTelkomActivations uid={currentUser.uid} />,
    4: <AgentTelkomActivations uid={currentUser.uid} />,
  };

  return (
    <div>
      <h1>Telkom Activations</h1>
      <BackLink />
      {reports[currentUser.rank]}
    </div>
  );
}
