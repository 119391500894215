import {
  faBuilding,
  faSimCard,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardActions, CardHeader, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function Settings() {
  const { currentUser } = useAuth();
  return (
    <div>
      <h1>Settings</h1>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card>
            <CardHeader
              title={"Dealers"}
              avatar={<FontAwesomeIcon icon={faUsers} />}
            />
            <CardActions>
              <Link to={"/settings/levels"}>
                <Button color={"primary"}>Levels</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader
              title={"RICA"}
              avatar={<FontAwesomeIcon icon={faSimCard} />}
            />
            <CardActions>
              <Link to={"/settings/rica"}>
                <Button color={"primary"}>RICA Settings</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        {currentUser.features?.suppliers && (
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={"Suppliers"}
                avatar={<FontAwesomeIcon icon={faBuilding} />}
              />
              <CardActions>
                <Link to={"/suppliers"}>
                  <Button color={"primary"}>Suppliers</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
