import React from "react";
import AllocateBox from "../allocate-stock/box/AllocateBox";
import CheckStock from "../allocate-stock/check/CheckStock";
import DealerAllocateStock from "../dealercomps/allocation/DealerAllocateStock";
import DealerSelectType from "../dealercomps/allocation/DealerSelectType";
import DealerDashboard from "../dealercomps/DealerDashboard";
import DealerEfficiency from "../dealercomps/reports/DealerEfficiency";
import DealerReports from "../dealercomps/reports/DealerReports";
import DrillDownReport from "../dealercomps/reports/DrillDownReport";
import SubDealerManage from "../dealercomps/SubDealerManage";
import SubDealerDetail from "../dealercomps/subdealers/SubDealerDetail";
import PrivateRoute from "./PrivateRoute";
import RicaForm from "../settings/rica/RicaForm";
import DailyConnectionsD from "../dealercomps/reports/datastudio/DailyConnectionsD";
import FinalConnectionsD from "../dealercomps/reports/datastudio/FinalConnectionsD";
import ActivationsD from "../dealercomps/reports/datastudio/ActivationsD";
import TelkomActivationsD from "../dealercomps/reports/datastudio/telkom/TelkomActivationsD";
import AllocateSingleSims from "../allocate-stock/sim/AllocateSingleSims";

export default function DealerRoutes() {
  return (
    <>
      <PrivateRoute exact path="/" component={DealerDashboard} />
      <PrivateRoute
        exact
        path="/allocate-stock/"
        component={DealerAllocateStock}
      />
      <PrivateRoute
        path="/allocate-stock/:network/select"
        component={DealerSelectType}
      />

      <PrivateRoute
        path="/allocate-stock/box/:boxType"
        component={AllocateBox}
      />
      <PrivateRoute exact path="/manage-dealers" component={SubDealerManage} />
      <PrivateRoute
        path="/manage-dealers/:dealerId"
        component={SubDealerDetail}
      />
      <PrivateRoute exact path="/dealer-reports" component={DealerReports} />
      <PrivateRoute path="/check" component={CheckStock} />
      <PrivateRoute
        path="/dd-report/:type/:rank/:dealerId/:year/:month/:network"
        component={DrillDownReport}
        key={window.location.pathname}
      />
      <PrivateRoute
        path="/efficiency/:dealerId/:rank"
        component={DealerEfficiency}
      />
      <PrivateRoute path="/rica" component={RicaForm} />
      <PrivateRoute
        path="/mtn-daily-connections"
        component={DailyConnectionsD}
      />
      <PrivateRoute
        path="/mtn-final-connections"
        component={FinalConnectionsD}
      />
      <PrivateRoute path="/mtn-activations" component={ActivationsD} />
      <PrivateRoute path="/telkom-activations" component={TelkomActivationsD} />
      <PrivateRoute path="/allocate-singles" component={AllocateSingleSims} />
    </>
  );
}
