import { faBoxes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, CardHeader, Typography } from '@mui/material'
import React from 'react'

export default function BrickBoxResult({ data }) {
    let conCount = 0;
    data.map(s => {
        if(s.connected) {
            conCount++
        }
    })
    return (
        <div>
            <CardHeader
                title="Search Result"
                avatar={
                    <Avatar><FontAwesomeIcon icon={faBoxes}/></Avatar>
                }
            />
            <Typography>SIMs found: {data.length}</Typography>
            <Typography>Connected: {conCount}</Typography>
        </div>
    )
}
