import { Check, SimCard } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import { functions } from "../../../firebase";

export default function ConfirmBarcode({
  open,
  close,
  simArray,
  simString,
  dealer,
}) {
  const [loading, setLoading] = useState(false);
  const { logAllocation } = useDb();
  const { currentUser, userLevel } = useAuth();

  const handleClose = () => {
    close();
  };

  const verifySim = async (data) => {
    const verify = functions.httpsCallable("isMySim");
    const result = await verify({
      sim: data,
      network: "vodacom",
      simId: "serial_number",
    });
    // See if the sim even exists.
    if (result.data.length < 1) {
      window.alert("Sim Not Found");
      return false;
    }
    // If you're a dealer, make sure you are actually allowed to do this.
    if (userLevel > 1) {
      if (result.data[0].allocationKey.includes(currentUser.uid)) return true;
      else return false;
    } else return true;
  };

  const handleConfirm = async () => {
    const simResult = verifySim(simArray[0]);
    if(!simResult) return;
    const verifySims = functions.httpsCallable("checkVodacomBarcode");
    const allocateSims = functions.httpsCallable("allocateString");
    setLoading(true);
    try {
      const simResult = await verifySims({
        network: "vodacom",
        simArray,
        simId: "serial_number",
      });
      if (!simResult.data) {
        throw {
          message: "Sims not found in database.",
        };
      }
      const allocationResult = await allocateSims({
        network: "vodacom",
        simArray,
        simId: "serial_number",
        allocationKey: dealer.allocationKey,
      });
      // TODO: Add some better error handling here and in the cloud function
      if (!allocationResult.data) {
        throw {
          message: "Error allocation SIMS in database.",
        };
      }
      const promiseArray = simArray.map((sp) => {
        return logAllocation(
          "vodacom",
          "sim",
          sp,
          dealer.id,
          dealer.allocationKey
        );
      });
      await Promise.all(promiseArray);
      setLoading(false);
      handleClose();
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Confirm Allocation</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {simArray &&
            simArray.map((sim) => (
              <Grid key={sim} item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title={sim}
                    avatar={
                      <Avatar>
                        <SimCard />
                      </Avatar>
                    }
                  />
                </Card>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <Alert severity="info">Loading. Please wait ...</Alert>}
        <Button disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={handleConfirm}
          startIcon={<Check />}
        >
          Confirm & Allocate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
