import React from "react";
import BackLink from "../../shared/BackLink";

export default function MTNMonthlyCommission() {
  return (
    <div>
      <h1>MTN Monthly Commissions</h1>
      <BackLink />
      <iframe
        width="100%"
        height="1200"
        src="https://datastudio.google.com/embed/reporting/4fedc017-173f-489d-88a4-b4bdd9196f2f/page/zHosC"
        frameBorder="0"
        style={{ border: 0 }}
        allowfullscreen
      ></iframe>
    </div>
  );
}
