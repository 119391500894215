import { Container } from '@mui/material'
import { Alert } from '@mui/material'
import React from 'react'

export default function AccountBlocked() {
  return (
    <div>
        <Container>
            <div style={{ marginTop: 50 }}>
                <Alert severity="error">This Hosting Account has been suspended.</Alert>
            </div>
        </Container>
    </div>
  )
}
