import React, { useState } from 'react'
import Select from 'react-select'
import SimCsvImporter from '../../shared/SimCsvImporter'
import { months, years } from '../../shared/constants'
import MtnConnectionsSchema from '../mtn/MtnConnectionsSchema'
import VodacomConnectionSchema from '../vodacom/VodacomConnectionSchema'
import { Avatar, Card, CardContent, CardHeader } from '@mui/material'
import { Warning } from '@mui/icons-material'
import TelkomConnectionsSchema from '../telkom/TelkomConectionsSchema'

export default function ImportConnections() {
    const [network, setNetwork] = useState(null)
    const [simId, setSimId] = useState(null)
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [msisdnId, setMsisdnId] = useState(null)
    const [schema, setSchema] = useState(null)

    const handleNetworkChange = (network) => {
        if(network === 'vodacom') {
            setSimId('serial_number')
            setMsisdnId('MSISDN')
            setSchema(<VodacomConnectionSchema />)
        }
        else if(network === 'mtn') {
            setSimId('kit_sim')
            setMsisdnId('msisdn')
            setSchema(<MtnConnectionsSchema />)
        }
        else if(network === 'cellC') {
        }
        else if(network === 'telkom') {
            setSimId('sim')
            setMsisdnId('msisdn')
            setSchema(<TelkomConnectionsSchema />)
        }
        setNetwork(network)
    }


    const networks = [
        { value: 'mtn', label: 'MTN'},
        { value: 'vodacom', label: 'Vodacom'},
        { value: 'telkom', label: 'Telkom'}
        // TODO: Add CellC
        // { value: 'cellC', label: 'CellC'},
    ];

    return (
        <div>
            <h1>Import Connections</h1>
            <Card>
                <CardHeader
                    title="Warning"
                    avatar={
                        <Avatar
                            style={{ backgroundColor: '#f48c09'}}
                        >
                            <Warning />
                        </Avatar>
                    }
                />
                <CardContent>
                    This import is for <b>Final Monthly Connections</b> only. This is not to be used for Daily Connections.<br/>
                    To import daily connections, please go to the <b>Daily Connections Import</b> screen.
                </CardContent>
            </Card>
            <br/>
            <Select
                options={networks}
                onChange={(val) => handleNetworkChange(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Network'}
            />
            <br/>
            <Select
                options={years}
                onChange={(val) => setYear(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Year'}
            />
            <br/>
            <Select
                options={months}
                onChange={(val) => setMonth(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Month'}
            />
            {
                network && year && month ?
                    <SimCsvImporter network={network} simId={simId} month={month} year={year} msisdnId={msisdnId} fileType='connections' schema={schema}/>
                : null
            }
        </div>
    )
}
