import { faArrowLeft, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CardContent, Dialog, DialogActions, DialogContent, Paper, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CreateDealer from './CreateDealer'
import PropTypes from 'prop-types';
import DealerList from './DealerList'
import { useDb } from '../../contexts/DatabaseContext'

function TabPanel(props) { 
    const { children, value, index, ...other } = props;
    
    return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
            {value === index && (
                <CardContent className="iso-card-content">
                    <Typography component={'span'}>{children}</Typography>
                </CardContent>  
            )}
            </div>
        )
    }
    
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ManageDealers() {
    const [comp, setComp] = useState(null)
    const [open, setOpen] = useState(false)
    const [tab, setTab] = useState(0)
    const { GetLevels } = useDb()
    const levels = GetLevels()
    

    const closeDialog = () => {
        setOpen(false)
        setComp(null)
    }

    const openDialog = (newComp) => {
        setComp(newComp)
        setOpen(true)
    }

    return (
        <div>
            <Link to='/'><Button color="primary" startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}>Back</Button></Link>
            <h1>Manage Dealers</h1>
            
            <Button onClick={() => openDialog(<CreateDealer closeDialog={closeDialog} />)} startIcon={<FontAwesomeIcon icon={faUserPlus}/>} color="primary" variant="contained">Create Dealer</Button>&nbsp;
            <br/>
            <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
                <DialogContent>
                    { comp }
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <br/>
            <Paper>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} indicatorColor="primary" >
                    
                    {
                        levels && levels.map((lv) => {
                            return (
                                <Tab label={lv.label} {...a11yProps(lv.level-1)} key={lv.id}/>
                            )
                        })
                    }
                </Tabs>
                {
                    levels && levels.map((lv) => {
                        console.log(lv)
                        return (
                            <TabPanel value={tab} index={lv.level-2} key={lv.id}>
                                <DealerList dealerLevel={lv.level} />
                            </TabPanel>
                        )
                    })
                }
            </Paper>
        </div>
    )
}
