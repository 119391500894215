import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AccountBlocked from '../blocked/AccountBlocked'

export default function BlockedRoutes() {
    return (
        <Switch>
            <Route path="*" component={AccountBlocked} />
        </Switch>
    )
}
