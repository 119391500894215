import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";
import { selectFieldProps } from "../../shared/constants";
import { functions } from "../../../firebase";

export default function ChangeParent({
  open,
  close,
  dealerId,
  level,
  oldParentId
}) {
  const { GetDealersOfLevel } = useDb();
  const dealers = GetDealersOfLevel(level - 1);

  const [newParent, setNewParent] = useState();
  const [state, setState] = useState({
    loading: false,
    error: null
  });

  const handleClose = () => {
    setState({
      loading: false,
      error: null
    });
    setNewParent(null);
    close();
  };

  const handleSelect = v => {
    console.log(v.value);
    setNewParent(v.value);
  };

  const handleSubmit = async () => {
    setState({
      loading: true,
      error: null
    });
    const changeParentCall = functions.httpsCallable("changeDealerParent");
    try {
      await changeParentCall({
        dealerId: dealerId,
        oldParentId,
        newParentId: newParent
      });
      handleClose();
    } catch (err) {
      setState({
        loading: false,
        error: err.message
      });
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle>Change Parent</DialogTitle>
      <DialogContent>
        <Select
          options={dealers}
          className="sim-select"
          {...selectFieldProps}
          onChange={handleSelect}
        />
      </DialogContent>
      <DialogActions>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
        <Button
          color="primary"
          disabled={!newParent || state.loading}
          onClick={handleSubmit}
        >
          {state.loading ? "Loading, please wait ..." : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
