import { Hidden } from "@mui/material";
import React from "react";

export default function SuperFinalConnections({ uid }) {
  var params = {
    "ds0.uid": uid,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);

  return (
    <div>
      <Hidden mdUp>
        {/* Phone Report */}
        <iframe
          width="350"
          height="650"
          src={`https://datastudio.google.com/embed/reporting/d82b2cc1-742c-4e92-b3ba-8cc82af7abb3/page/OjHuC?params=${encodedParams}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
      <Hidden smDown>
        {/* Desktop Report */}
        <iframe
          width="1200"
          height="900"
          src={`https://datastudio.google.com/embed/reporting/ffe9a158-87dd-4fd8-8b6a-03c01b294145/page/OjHuC?params=${encodedParams}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
    </div>
  );
}
