import React from 'react'
import { ImporterField } from 'react-csv-importer'

export default function MegatelSchema() {
    return (
        <>
            <ImporterField name="sim" label="SIM (Serial)" />
            <ImporterField name="box" label="Box (Carton 1)" />
        </>
    )
}
