import React from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import BackLink from '../../../shared/BackLink'
import DealerFinalConnections from './final-connections/DealerFinalConnections'
import SuperFinalConnections from './final-connections/SuperFinalConnections'

export default function FinalConnectionsD() {
    const { currentUser } = useAuth()

    const reportStack = {
        2: <SuperFinalConnections uid={currentUser.uid}/>,
        3: <SuperFinalConnections uid={currentUser.uid}/>,
        4: <DealerFinalConnections uid={currentUser.uid}/>
    }
    
    return (
        <div>
            <h1>Final Connections</h1>
            <BackLink />
            { reportStack[currentUser.rank] }
        </div>
    )
}
