import React from "react";
import SimCsvImporter from "../../shared/SimCsvImporter";
import CellCSchema from "./CellCSchema";

export default function ImportCellC() {
  return (
    <SimCsvImporter
      network="cellc"
      simId="SerialNo"
      schema={<CellCSchema />}
      fileType="stock"
    />
  );
}
