import React from "react";
import SimCsvImporter from "../../shared/SimCsvImporter";
import DashSchema from "./DashSchema";

export default function ImportDash() {
  return (
    <SimCsvImporter
      network="dash"
      simId="SerialNo"
      schema={<DashSchema />}
      fileType="stock"
    />
  );
}
