import { Box, Button, LinearProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { db, storage } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../contexts/AuthContext";
import { useDb } from "../../contexts/DatabaseContext";
import Alert from '@mui/material/Alert';
function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
};

export default function FileUploader({ sendFile, label, storagePath }) {
  const { currentUser } = useAuth();
  const { addUploadInstance } = useDb();

  const storageRef = storagePath
    ? storage.ref().child(storagePath)
    : storage.ref("users").child(currentUser.uid);
  const [fileSelected, setFileSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [fileNameState, setFileNameState] = useState(null);
  const [displayResult, setDisplayResult] = useState(false);
  const [resultPending, setResultPending] = useState(false);
  const [uploadResult, setUploadResult] = useState("");

  const handleFileChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
    console.log(file);
    const fileName = Math.random().toString(36).slice(-10);
    setFileNameState(fileName);
    const uploadTask = storageRef
      .child(`documents/${fileName}/${file.name}`)
      .put(file);
    uploadTask.on(
      "state_changed",
      snapshot => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(upProgress);
      },
      error => {
        setError("Error during File Upload");
        setIsUploading(false);
        setFileSelected(false);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          setProgress(100);
          setIsUploading(false);
          setFileSelected(false);
          sendFile({
            url,
            fileName: file.name
          });
        });
      }
    );
  };

  useEffect(() => {
    if (fileNameState) {
      setResultPending(true);
      setDisplayResult(true);
      addUploadInstance(currentUser.uid, fileNameState).then(() => {
        db.collection("upload_instances")
          .where("fileName", "==", fileNameState)
          .limit(1)
          .onSnapshot(snapshot => {
            const fileDoc = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            if (fileDoc[0].result) {
              console.log(fileDoc[0].result);
              setResultPending(false);
              setUploadResult(fileDoc[0].result);
            }
          });
      });
    } else {
      setResultPending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileNameState]);

  return (
    <Box style={{ width: "100%", marginTop: "1rem" }}>
      {label || "Upload File"}
      <br />
      <br />
      <input
        type="file"
        onChange={handleFileChange}
        style={{ marginBottom: "1rem" }}
      />
      <br />
      <LinearProgressWithLabel value={progress} />
      <br />
      {error && (
        <Alert style={{ width: "100%", marginBottom: "1rem" }} severity="error">
          Error Uploading file
        </Alert>
      )}
      <Button
        disableElevation
        disabled={!fileSelected || isUploading}
        onClick={handleUploadStart}
        startIcon={<FontAwesomeIcon icon={faUpload} />}
        variant="contained"
        color="primary"
      >
        Upload
      </Button>
      {displayResult && (
        <div>
          {resultPending ? (
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <LinearProgress />
              <p>Please wait while we process your file...</p>
            </div>
          ) : (
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <p>Result: {uploadResult}</p>
            </div>
          )}
        </div>
      )}
    </Box>
  );
}
