import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowDownward, LocalShipping, SimCard } from "@mui/icons-material";
import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";
import { useAuth } from "../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { functions } from "../../../firebase";
import { CloudDownload, SimCardOutlined } from "@material-ui/icons";
import { parseJsonArrayToCsv } from "../../shared/utils/parseJsonToArrayToCsv";
import moment from "moment";
import { networks } from "../../shared/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function AllocateBox() {
  // This must be either boxes or bricks
  const { boxType } = useParams();

  const [network, setNetwork] = useState(null);
  const [boxNumber, setBoxNumber] = useState("");
  const [dealer, setDealer] = useState(null);
  const [allocation, setAllocation] = useState({
    vodacom: [],
    cellc: [],
    telkom: [],
    mtn: [],
    hello: [],
    dash: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorDialog, setErrorDialog] = useState(false);
  const [check, setCheck] = useState(null);

  const { currentUser, userLevel } = useAuth();
  const {
    allocateBigQueryBoxBrick,
    SubGetChildDealers,
    GetAllDealers,
    CheckIfBoxExists,
    checkIfBoxMine,
  } = useDb();
  const dealers = userLevel <= 1 ? GetAllDealers() : SubGetChildDealers();

  const dealerOptions = dealers.map((d) => ({
    value: d,
    label: `${d.contactName} ${d.contactSurname} - (Level ${d.rank})`,
  }));

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
    setLoading(false);
  };

  const checkIfMyBox = async () => {
    const isMyBox = await checkIfBoxMine(network, boxType, boxNumber);
    if (currentUser.rank === 1 || "1") return true;
    else return isMyBox;
  };

  const handleAdd = async () => {
    const boxArray = allocation[network] || [];
    setLoading(true);
    setError(null);
    try {
      if (boxArray && boxArray.includes(boxNumber)) {
        setError("This box has not been allocated to you.");
        setErrorDialog(true);
        return;
      }
      const myBox = await checkIfMyBox();
      if (!myBox) {
        setError("This box has not been allocated to you.");
        setErrorDialog(true);
        return;
      }

      const res = await CheckIfBoxExists(network, boxType, boxNumber);
      if (res === true) {
        boxArray.push(boxNumber);
        setAllocation({
          ...allocation,
          [network]: boxArray,
        });
        setLoading(false);
        setBoxNumber("");
      } else if (res === false) {
        setErrorDialog(true);
        setCheck("This box is already allocated to another Agent");
      }
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
      setBoxNumber("");
    }
  };

  const handleReAllocation = () => {
    const boxArray = allocation[network];
    setLoading(true);
    setError(null);
    try {
      boxArray.push(boxNumber);
      setLoading(false);
      setBoxNumber("");
      setErrorDialog(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      setBoxNumber("");
    }
  };

  const handleNetworkChange = (network) => {
    setNetwork(network);
  };

  const [currentTask, setCurrentTask] = useState();
  const [completedJobs, setCompletedJobs] = useState([]);
  const [jobSubmitted, setJobSubmitted] = useState(false);

  const addCompletedJob = (resultData, networkName) => {
    setCompletedJobs([...completedJobs, { networkName, resultData }]);
  };

  const handleAllocation = async (networkName) => {
    const allocateFunction = functions.httpsCallable("allocateBoxes");
    setCurrentTask(`Allocating ${networkName.toUpperCase()}. Please wait ...`);
    const result = await allocateFunction({
      network: networkName,
      boxArray: allocation[networkName],
      allocationKey: dealer.allocationKey,
      boxType,
    });
    addCompletedJob(result.data, networkName.toUpperCase());
  };

  const handleSubmit = async () => {
    setLoading(true);
    // Loop through each network in the allocation object
    for (const networkName of Object.keys(allocation)) {
      if (allocation[networkName].length > 0) {
        await handleAllocation(networkName);
      }
    }
    await allocateBigQueryBoxBrick(boxType, allocation, dealer);
    setCurrentTask(null);
    setAllocation({
      vodacom: [],
      cellc: [],
      telkom: [],
      mtn: [],
      hello: [],
      dash: [],
    });
    setJobSubmitted(true);
    setLoading(false);
  };

  const handleCsvDownload = (records) => {
    const today = new Date();
    const dateFormatted = moment(today).format("YYYY-MM-DD");
    const fileName = `${network}_${dealer.contactName}_${dealer.contactSurname}_${dateFormatted}.csv`;
    const csvData = parseJsonArrayToCsv(records);
    const csvFile = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvFile, fileName);
    } else {
      csvURL = window.URL.createObjectURL(csvFile);
    }
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  };

  const networkCards = networks.map((network) => (
    <Grid item xs={12} sm={6} md={3} lg={3} key={network.value}>
      <Card>
        <CardHeader title={network.label} />
        <CardContent>
          {allocation[network.value]?.map((box) => (
            <li key={box}>{box}</li>
          ))}
        </CardContent>
      </Card>
    </Grid>
  ));

  return (
    <div>
      <h1>Allocate {boxType.toUpperCase()}</h1>
      <Stack spacing={1}>
        {error ? (
          <Typography style={{ color: "red" }}>{error}</Typography>
        ) : null}
        {!jobSubmitted && (
          <>
            <Select
              options={dealerOptions}
              onChange={(val) => setDealer(val.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              placeholder={"Select a Dealer"}
            />
            <Select
              options={networks}
              onChange={(val) => handleNetworkChange(val.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              placeholder={"Select a Network"}
            />
          </>
        )}

        {dealer && !jobSubmitted && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={9} lg={9}>
              <TextField
                fullWidth
                variant="outlined"
                label={`Scan / Type ${boxType} Number`}
                onChange={(e) => setBoxNumber(e.target.value)}
                value={boxNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Button
                onClick={handleAdd}
                disabled={loading}
                size="large"
                startIcon={<ArrowDownward />}
                variant="contained"
                color="primary"
              >
                Add to Allocation
              </Button>
            </Grid>
          </Grid>
        )}
        {dealer ? (
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <LocalShipping />
                </Avatar>
              }
              title={`${boxType.toUpperCase()} To Allocate:`}
            />
            <CardContent>
              {!jobSubmitted && (
                <Stack spacing={1}>
                  <Grid container spacing={3}>
                    {allocation && networkCards}
                  </Grid>
                </Stack>
              )}

              <Stack spacing={1} sx={{ mt: 2 }}>
                {error ? (
                  <Typography style={{ color: "red" }}>{error}</Typography>
                ) : null}
                {completedJobs && completedJobs.length > 0 && (
                  <Card>
                    <CardHeader
                      title="Completed Allocations"
                      subheader="You can download csv files of your allocated stock here"
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        {completedJobs.map((job, index) => (
                          <Grid item xs={12} md={4} key={index}>
                            <Card>
                              <CardHeader
                                avatar={<SimCardOutlined />}
                                title={job.networkName}
                                subheader={`${job.resultData?.length} SIMs`}
                                action={
                                  <Button
                                    startIcon={<CloudDownload />}
                                    size="small"
                                    onClick={() =>
                                      handleCsvDownload(job.resultData)
                                    }
                                  >
                                    Get CSV
                                  </Button>
                                }
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                )}
                {currentTask && <Alert severity="info">{currentTask}</Alert>}
                {!jobSubmitted ? (
                  <Button
                    disabled={loading}
                    fullWidth
                    onClick={handleSubmit}
                    size="large"
                    startIcon={<SimCard />}
                    variant="contained"
                    color="primary"
                  >
                    Allocate SIMS
                  </Button>
                ) : (
                  <Alert severity="success">Allocation Complete!</Alert>
                )}
              </Stack>
            </CardContent>
          </Card>
        ) : null}
      </Stack>

      <Dialog
        open={errorDialog}
        maxWidth={"sm"}
        fullWidth
        onClose={handleErrorDialogClose}
      >
        <DialogContent>
          <h2>Are you Sure?</h2>
          <Typography variant="h6" color="error">
            {check}
          </Typography>
          <Typography>Are you sure you want to re-allocate this box</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleReAllocation}
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faCheck} />}
          >
            Yes
          </Button>
          <Button
            onClick={handleErrorDialogClose}
            variant="contained"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faWindowClose} />}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
