import React from "react";

export default function AgentTelkomActivations({ uid }) {
  var params = {
    "ds0.uid": uid,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);
  return (
    <div>
      <iframe
        width="350"
        height="700"
        src={`https://datastudio.google.com/embed/reporting/55124046-c187-4559-b6e7-bcbf923c8182/page/G715C?params=${encodedParams}`}
        frameBorder="0"
      ></iframe>
    </div>
  );
}
