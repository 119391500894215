import { Button, Card, CardActions, CardContent, CircularProgress, IconButton, InputAdornment, Snackbar, TextField } from '@mui/material'
import React, { useState } from 'react'
import { textFieldProps } from '../../shared/constants'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Save } from '@mui/icons-material';
import { useDb } from '../../../contexts/DatabaseContext';

export default function RicaSettings() {
    const { updateRicaSettings, getRicaSettings } = useDb()

    const [state, setState] = useState({})
    const [showPw, setShowPw] = useState(false)

    //Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false)

    const [pageLoaded, setPageLoaded] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSave = async () => {
        setError(null)
        setLoading(false)
        try {
            await updateRicaSettings(state).then(() => {
                setSnackOpen(true)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }


    const firstLoad = async () => {
        const ricaSettings = await getRicaSettings()
        setState(ricaSettings)
        setPageLoaded(true)
    }

    if(!pageLoaded) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>RICA Settings</h1>
            <Card>
                <CardContent>
                    <TextField
                        {...textFieldProps}
                        label="Group Name"
                        onChange={e => setState({ ...state, groupName: e.target.value })}
                        value={state.groupName}
                    />
                    <TextField
                        {...textFieldProps}
                        label="Agent"
                        onChange={e => setState({ ...state, Agent: e.target.value })}
                        value={state.Agent}
                    />
                    <TextField
                        {...textFieldProps}
                        label="Password"
                        type={showPw ? "text" : "password"}
                        value={state.password}
                        InputProps={
                            {
                            endAdornment:
                                <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowPw(!showPw)}
                                      onMouseDown={handleMouseDownPassword}
                                      size="large">
                                    { showPw ? <Visibility /> : <VisibilityOff /> }
                                  </IconButton>
                                </InputAdornment>
                            }
                        }
                        onChange={e => setState({ ...state, password: e.target.value })}
                    />
                </CardContent>
                <CardActions>
                    <Button disabled={loading} onClick={handleSave} variant="contained" color="primary" startIcon={<Save />}>Save Changes</Button>
                </CardActions>
            </Card>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={snackOpen}
                onClose={() => setSnackOpen(false)}
                autoHideDuration={5000}
                message="RICA Settings Updated!"
            />
        </div>
    );
}
