import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function NetworkImports() {
  const buttonStyle = {
    marginTop: 10,
  };

  return (
    <div>
      <h1>Network Imports</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="MTN"
              avatar={
                <Avatar src="https://firebasestorage.googleapis.com/v0/b/air-save.appspot.com/o/network_logos%2FMTN.png?alt=media&token=378f45f9-4d98-4cb8-9378-6a83d27954a5" />
              }
            />
            <CardContent>
              <List dense>
                <ListItem>
                  <Link to="/mtn-simci-import">
                    <ListItemText>MTN SIMCI</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/mtn-simcicon-import">
                    <ListItemText>MTN SIMCICON</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/mtn-churn-import">
                    <ListItemText>MTN DEACT (Churn)</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/mtn-base-import">
                    <ListItemText>MTN Base</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/mtn-usage-import">
                    <ListItemText>MTN Usage</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/mtn-sim-swaps-import">
                    <ListItemText>MTN Sim Swaps</ListItemText>
                  </Link>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Telkom"
              avatar={
                <Avatar src="https://firebasestorage.googleapis.com/v0/b/air-save.appspot.com/o/network_logos%2FTelkom.png?alt=media&token=9048d39c-f900-4896-8ac5-3b929a46a24f" />
              }
            />
            <CardContent>
              <List dense>
                <ListItem>
                  <Link to="/telkom-adl-import">
                    <ListItemText>Telkom Direct: PRE ADL</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/telkom-ong-import">
                    <ListItemText>Telkom Direct: PRE ONG</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/smartcall-telkom-monthly">
                    <ListItemText>Smartcall: Montly Activations</ListItemText>
                  </Link>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Vodacom"
              avatar={
                <Avatar src="https://firebasestorage.googleapis.com/v0/b/air-save.appspot.com/o/network_logos%2FVodacom.png?alt=media&token=0ec1d12a-f60d-4c4b-9b92-c44ca2593cc0" />
              }
            />
            <CardContent>
              <List dense>
                <ListItem>
                  <Link to="/vodacom-imports/del">
                    <ListItemText>TPC: Del</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/vodacom-imports/comm">
                    <ListItemText>TPC: Comm (Activations)</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/vodacom-imports/conn">
                    <ListItemText>TPC: Conn (Connections)</ListItemText>
                  </Link>
                </ListItem>
                {/* <ListItem>
                  <Link to="/vodacom-imports/rev">
                    <ListItemText>TPC: Rev (Recharges)</ListItemText>
                  </Link>
                </ListItem> */}
                <ListItem>
                  <Link to="/vodacom-imports/daily_conn_per_dealer">
                    <ListItemText>
                      SmartCall: Daily Conn Per Dealer
                    </ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/vodacom-imports/smartcall_daily_conn">
                    <ListItemText>Smartcall: Daily Conn</ListItemText>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/vodacom-imports/activations_detail_per_dealer">
                    <ListItemText>
                      Smartcall: Activations Detail Per Dealer
                    </ListItemText>
                  </Link>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <h2>Vodacom</h2>
      <h2>KCM</h2>
      <Link to="/kc-imports">
        <Button style={buttonStyle} color="primary" variant="contained">
          KCM Imports
        </Button>
      </Link>
      &nbsp;
    </div>
  );
}
