import { Avatar, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LockOutlined } from '@mui/icons-material';
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    success: {
        color: 'green'
    }
}));

export default function ForgotPassword({ toggleView }) {
    const classes = useStyles()
    const [email, setEmail] = useState(null)
    const { forgotPassword } = useAuth()

    // Action State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault();
    
        try {
          setError('');
          setLoading(true);
          await forgotPassword(email).then(() => {
              setMessage(true)
          })
        } catch (err) {
          setError(err.message);
        }
        setLoading(false);
    }

    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              {
                  message
                  ?
                  <Typography className={classes.success}>Request Sent. Please check your inbox for further instructions.</Typography>
                  :
                  null
              }
              <span style={{ color: 'red' }}>{error}</span>
              <br />
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send Reset Email
              </Button>
              <Grid container>
                <Grid item xs>
                  <Typography className="sim-text-link" color="primary" onClick={toggleView} variant="body2">
                    Back to login
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      );
}
