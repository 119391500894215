import React from "react";
import SimCsvImporter from "../../shared/SimCsvImporter";
import HelloSchema from "./HelloSchema";

export default function ImportHello() {
  return (
    <SimCsvImporter
      network="hello"
      simId="SerialNo"
      schema={<HelloSchema />}
      fileType="stock"
    />
  );
}
