import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack, Folder, People, PersonAdd } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import FileUploader from "../../shared/FileUploader";
import DealerFiles from "../DealerFiles";
import ChangeParent from "./ChangeParent";
import EditEmail from "./EditEmail";
import { functions } from "../../../firebase";
import { textFieldProps } from "../../shared/constants";
import { Alert } from '@mui/material';

export default function RDealerDetail() {
  const { dealerId } = useParams();
  const history = useHistory();
  const {
    getDealer,
    GetDealerFiles,
    attachFileToDealer,
    addToFrontOfAllocationKey,
  } = useDb();
  const { currentUser } = useAuth();
  const [dealer, setDealer] = useState();
  const files = GetDealerFiles(dealerId);
  const [changeParent, setChangeParent] = useState(false);
  const [editEmailOpen, setEditEmailOpen] = useState(false);
  const [prependValue, setPrependValue] = useState();
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  useEffect(async () => {
    const d = await getDealer(dealerId);
    console.log(d);
    setDealer(d);
    return;
  }, [editEmailOpen, changeParent]);

  const getFile = async (file) => {
    setState({
      loading: true,
      error: null,
    });
    try {
      await attachFileToDealer(dealer.id, file).then(() => {
        setState({
          loading: false,
          error: null,
        });
      });
    } catch (err) {
      setState({
        loading: false,
        error: err.message,
      });
    }
  };

  const fixHistoricalAllocation = async () => {
    const fixFunction = functions.httpsCallable("fixAgentAllocation");
    setState({
      loading: true,
      error: null,
    });
    try {
      await fixFunction({
        dealerId: dealer.id,
        newAllocationKey: dealer.allocationKey,
      });
      window.alert("Done");
    } catch (err) {
      window.alert(err.message);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const handlePrepend = async () => {
    setState({
      loading: true,
      error: null,
    });
    try {
      await addToFrontOfAllocationKey({
        dealerId,
        frontValue: prependValue,
        allocationKey: dealer.allocationKey,
      });
      setState({
        loading: false,
        error: null,
      });
      window.alert("Task Complete");
    } catch (err) {
      window.alert(err.message);
      setState({
        loading: false,
        error: null,
      });
    }
  };

  return !dealer ? (
    <CircularProgress />
  ) : (
    <div>
      <Container>
        <h1>
          {dealer.contactName} {dealer.contactSurname}
        </h1>
        <div className="back-link">
          <Button
            color="primary"
            startIcon={<ArrowBack />}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <Typography>ID Number: {dealer.contactIdNo}</Typography>
                <Typography>Phone Number: {dealer.phone}</Typography>
                <Typography>Email: {dealer.email}</Typography>
                <br />
                <Typography>Street Address: {dealer.street}</Typography>
                <Typography>Suburb: {dealer.suburb}</Typography>
                <Typography>City: {dealer.city}</Typography>
                <Typography>Province: {dealer.province}</Typography>
                <br />
                <Typography>Dealer Type: {dealer.dealerType}</Typography>
                <Typography>
                  Dealer Parent (Manager): {dealer.parentDealerName || "..."}
                </Typography>
              </CardContent>
              <CardActions>
                <Link to={`/dealer-agents/${dealerId}`}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<People />}
                  >
                    View All Agents
                  </Button>
                </Link>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<PersonAdd />}
                  onClick={() => setChangeParent(true)}
                >
                  Change Parent
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<PersonAdd />}
                  onClick={() => setEditEmailOpen(true)}
                >
                  Edit/Add Email & PW
                </Button>
              </CardActions>
            </Card>
            <br />
            {currentUser.master_account && (
              <Card>
                <Alert severity="warning">Warning: The below action must ONLY be performed on the lowest ranked dealers (Agents) in the system.</Alert>
                <CardContent>
                  <span>{dealer.allocationKey}</span>
                  <br/><br/>
                  <TextField
                    {...textFieldProps}
                    label="Pre Pend Manager ID"
                    onChange={(e) => setPrependValue(e.target.value)}
                    value={prependValue}
                  />
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<PersonAdd />}
                    onClick={fixHistoricalAllocation}
                    disabled={state.loading}
                  >
                    Fix Historical Allocation
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<PersonAdd />}
                    onClick={handlePrepend}
                    disabled={state.loading || !prependValue}
                  >
                    Issue Prepend Value
                  </Button>
                </CardActions>
              </Card>
            )}
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={"Attachments"}
                avatar={
                  <Avatar>
                    <Folder />
                  </Avatar>
                }
              />
              <CardContent>
                {currentUser && currentUser.level <= 1 ? (
                  <>
                    <DealerFiles files={files} />
                    <FileUploader sendFile={getFile} />
                  </>
                ) : null}
              </CardContent>
            </Card>
            <br />
          </Grid>
        </Grid>
      </Container>

      <ChangeParent
        open={changeParent}
        close={() => setChangeParent(false)}
        dealerId={dealer.id}
        level={dealer.rank}
        oldParentId={dealer.parentDealer}
      />

      <EditEmail
        open={editEmailOpen}
        close={() => setEditEmailOpen(false)}
        dealer={dealer}
      />
    </div>
  );
}
