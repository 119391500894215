import React from "react";
import { ImporterField } from "react-csv-importer";

export default function HelloSchema() {
  return (
    <>
      <ImporterField name="SerialNo" label="SerialNo" />
      <ImporterField name="InnerBoxNo" label="InnerBoxNo'" />
      <ImporterField name="OuterBoxNo" label="OuterBoxNo" />
    </>
  );
}
