import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userLevel, setUserLevel] = useState();
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  async function logout() {
    return await auth.signOut().then(() => {
      setCurrentUser(null);
    });
  }

  function forgotPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function loginWithPhone(phoneNo, verifier) {
    return auth.signInWithPhoneNumber(phoneNo, verifier);
  }

  async function getUserDoc(uid) {
    return db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        return doc.data();
      });
  }

  async function getCompanyDoc(companyId) {
    return db
      .collection("companies")
      .doc(companyId)
      .get()
      .then((doc) => {
        return doc.data();
      });
  }

  async function publicDealerRequestCheck(number) {
    return await db
      .collection("companies")
      .doc("rng1jJmqJZCG4IGqxpfE")
      .collection("dealerRequests")
      .where("phone", "==", number)
      .get()
      .then((snap) => {
        if (snap.empty) {
          throw {
            message: "This phone number has not been added.",
          };
        }
      });
  }

  const formatPhoneNumber = (number) => {
    let formattedPhoneNumber = number;

    formattedPhoneNumber = formattedPhoneNumber.replace(/ /g, "");

    if (formattedPhoneNumber.startsWith("0")) {
      formattedPhoneNumber = `+27${formattedPhoneNumber.slice(1, 10)}`;
    }

    if (formattedPhoneNumber.startsWith("+270")) {
      formattedPhoneNumber = `+27${formattedPhoneNumber.slice(4, 13)}`;
    }

    if (formattedPhoneNumber.length === 9) {
      formattedPhoneNumber = `+27${formattedPhoneNumber}`;
    }

    return formattedPhoneNumber;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getUserDoc(user.uid);
        const level = userDoc.rank;
        const companyDoc = await getCompanyDoc(userDoc.companyId);
        setUserLevel(level);
        setCurrentUser({ ...user, level, ...userDoc, ...companyDoc });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  async function checkPhoneExists(phone) {
    return db
      .collection("dealers")
      .where("phone", "==", phone)
      .get()
      .then((snap) => {
        return !snap.empty;
      });
  }

  const value = {
    currentUser,
    login,
    logout,
    userLevel,
    forgotPassword,
    loginWithPhone,
    publicDealerRequestCheck,
    formatPhoneNumber,
    checkPhoneExists,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
