import { Hidden } from "@mui/material";
import React from "react";

export default function DealerLevelDailyConnections({ uid }) {
  var params = {
    "ds0.uid": uid,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);

  return (
    <div>
      <Hidden mdUp>
        {/* Phone Report */}
        <iframe
          width="350"
          height="650"
          src="https://datastudio.google.com/embed/reporting/338266b0-1dc8-4484-aa79-01ad594f743e/page/OjHuC"
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
      <Hidden smDown>
        {/* Desktop Report */}
        <iframe
          width="350"
          height="650"
          src="https://datastudio.google.com/embed/reporting/338266b0-1dc8-4484-aa79-01ad594f743e/page/OjHuC"
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
    </div>
  );
}
