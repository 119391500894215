import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import VodacomActivationsImport from './VodacomActivationsImport'
import VodacomConnectionImport from './VodacomConnectionImport'
import VodacomOGRImport from './VodacomOGRImport'

export default function NetworkImportVC() {
    return (
        
        <div>
            <Link to='/'><Button color="primary" startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}>Back</Button></Link>
            <h1>Import Vodacom Network Files</h1>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <VodacomConnectionImport />
                </Grid>
                <Grid item xs={2}>
                    <VodacomActivationsImport />
                </Grid>
                <Grid item xs={2}>
                    <VodacomOGRImport />
                </Grid>
            </Grid>
        </div>
    )
}
