import { Alert } from "@mui/material";
import React from "react";

export default function CheckStockDS() {
  return (
    <div>
      <Alert severity="info">
        Please Note: Data may be delayed by up to 2 hours.
      </Alert>
      <br />
      <iframe
        width="1200"
        height="900"
        src="https://datastudio.google.com/embed/reporting/3dcae5d7-77d3-442b-b8a0-b3d0a8c77927/page/Q4axC"
        frameBorder="0"
        allowfullscreen
      ></iframe>
    </div>
  );
}
