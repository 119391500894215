import { Button, Card, CardActions, CardContent, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, FormControl, InputLabel, MenuItem } from '@mui/material'
import { ArrowLeftOutlined, ArrowRightAlt } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { Pagination } from '@mui/material';
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../contexts/AuthContext'

export default function DrillDownReport() {
    const { type, rank, dealerId, year, month, network } = useParams()
    const { getDrillDownConnections, getDrillDownActivations, getDrillDownConnectionsAdmin, getDrillDownActivationsAdmin, getDealerName, GetLevelsBelow } = useDb()
    const { currentUser } = useAuth()
    const history = useHistory()
    const levels = GetLevelsBelow(parseInt(currentUser.level))
    
    // Action State
    const [loaded, setLoaded] = useState(false)
    const [dealers, setDealers] = useState([])
    const [dealerName, setDealerName] = useState(null)
    const [selectedLevel, setSelectedLevel] = useState(null)

    // Pagination
    const itemsPerPage = 50; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(dealers.length / itemsPerPage)
    );

    const handleLevelChange = (e) => {
        setSelectedLevel(e.target.value-1)
        // console.log(e.target.value)
        history.push(`/dd-report/activations/${e.target.value-1}/${dealerId}/${year}/${month}/${network}`)
    }
    
    const firstLoad = async () => {
        const parsedRank = parseInt(rank)
        if(type === 'connections') {
            const d = rank === '1' ? await getDrillDownConnectionsAdmin(parsedRank+1, month, year, network) : await getDrillDownConnections(parsedRank+1, dealerId, month, year, network)
            const dName = await getDealerName(dealerId)
            setDealerName(dName)
            setSelectedLevel(parsedRank)
            setDealers(d)
            setLoaded(true)
        }
        else if (type === 'activations') {
            const d = rank === '1' ? await getDrillDownActivationsAdmin(parsedRank+1, month, year, network) : await getDrillDownActivations(parsedRank+1, dealerId, month, year, network)
            const dName = await getDealerName(dealerId)
            setSelectedLevel(parsedRank)
            setDealerName(dName)
            setDealers(d)
            setLoaded(true)
        }
    }

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            {
                rank === '1'
                ?
                <h3>Total allocated {type} for Level {parseInt(rank) + 1}</h3>
                :
                <p>Total allocated {type} for all Level {parseInt(rank) + 1} dealers under <b>{dealerName}</b></p>
            }
            <Button onClick={() => history.goBack()} variant="contained" color="primary" startIcon={<ArrowLeftOutlined />}>Back</Button>
            <br/><br/>
            <Card>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dealer Level</TableCell>
                                    <TableCell>Dealer Name</TableCell>
                                    <TableCell>Dealer Phone</TableCell>
                                    <TableCell>Total {type}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                                
                            </TableHead>
                            <TableBody>
                                {
                                    dealers && dealers
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(d => {
                                        return (
                                            <TableRow key={d.id}>
                                                <TableCell>{d.rank}</TableCell>
                                                <TableCell>{d.dealerName}</TableCell>
                                                <TableCell>{d.phone}</TableCell>
                                                <TableCell>{ type === 'connections' ? d.connTotal : d.actTotal }</TableCell>
                                                <TableCell align="right">
                                                    <Link to={`/drilldown-sims/${network}/${d.id}/${year}/${month}/${
                                                        type === 'connections' ? 'connected' : 'activated'
                                                    }/${
                                                        type === 'connections' ? 'connection' : 'activation'
                                                    }`}>
                                                        <Button color="primary" variant="outlined" endIcon={<ArrowRightAlt />}>View Sims</Button>
                                                    </Link>
                                                    <Link to={`/dd-report/${type}/${d.rank}/${d.id}/${year}/${month}/${network}`}>
                                                        <Button color="primary" variant="outlined" endIcon={<ArrowRightAlt />}>View Downstream</Button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(dealers.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    
                    
                    <FormControl>
                        <InputLabel>Downstream Level</InputLabel>
                        <Select
                            value={selectedLevel+1}
                            onChange={handleLevelChange}
                            style={{
                                width: 170
                            }}
                        >
                            {
                                levels && levels.map(l => {
                                    return (
                                        <MenuItem key={l.id} value={l.level}>{l.level}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                    <CSVLink
                        data={dealers}
                        style={{ marginTop: 5, textDecoration: 'none'}}
                        filename={`${type}_${dealerName}`}
                    >
                        <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFileExcel}/>} style={{ backgroundColor: 'green', color: '#fff'}}> Export to CSV</Button>
                    </CSVLink>
                </CardActions>
            </Card>
        </div>
    )
}
