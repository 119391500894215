import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid, LinearProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { networks, selectFieldProps } from '../../shared/constants'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker } from '@material-ui/pickers'
import { useDb } from '../../../contexts/DatabaseContext'
import Chart from "react-apexcharts";
import { MonetizationOn, Wifi } from '@mui/icons-material'

export default function DealerEfficiency() {
    const { dealerId, rank } = useParams()
    const { getDealerEfficiency } = useDb()

    const [dealerStock, setDealerStock] = useState(null)
    const [connections, setConnections] = useState(0)
    const [activations, setActivations] = useState(0)

    const [chartState, setChartState] = useState({
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                toolbar: {
                  show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '70%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                        dropShadow: {
                            enabled: true,
                            top: 3,
                            left: 0,
                            blur: 4,
                            opacity: 0.24
                        }
                    },
                    track: {
                        background: '#fff',
                        strokeWidth: '67%',
                        margin: 0, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: -3,
                            left: 0,
                            blur: 4,
                            opacity: 0.35
                        }
                    },

                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#888',
                            fontSize: '17px'
                        },
                        value: {
                            formatter: function (val) {
                                return parseInt(val);
                            },
                            color: '#111',
                            fontSize: '36px',
                            show: true,
                        }
                    }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#ABE5A1'],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Percent'],
        }
    })

    const getReport = async () => {
        setLoading(true)
        setError(null)
        try {
            await getDealerEfficiency(dealerId, rank, state).then((dStock) => {
                setLoading(false)
                setDealerStock(dStock)
                const connCount = checkStockState('connected', dStock)
                const actCount = checkStockState('activated', dStock)
                setConnections(connCount)
                setActivations(actCount)
                console.log(dStock)
            })
        }
        catch(err) {
            console.log(err.message)
            setError(err.message)
            setLoading(false)
        }
    }

    const checkStockState = (state, stockArray) => {
        let simCount = 0
        stockArray.map(sim => {
            if(sim[state]){
                simCount ++
            }
        })
        return simCount
    }

    const [state, setState] = useState({})

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    return (
        <div>
            <h1>Dealer Efficiency</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                    <Card>
                        <CardContent>
                            <Select
                                {...selectFieldProps}
                                options={networks}
                                placeholder="Select Network ..."
                                onChange={v => setState({...state, network: v.value})}
                            />
                            <br/>
                            <Typography>Select Dates for Stock Allocated Between:</Typography>
                            <br/>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <>
                                    <DatePicker label="From" fullWidth inputVariant="outlined" format="dd/MM/yyyy" value={state.startDate} onChange={d => setState({...state, startDate: d })} />
                                </>
                            </MuiPickersUtilsProvider>
                            <br/><br/>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <>
                                    <DatePicker label="To" fullWidth inputVariant="outlined" format="dd/MM/yyyy" value={state.endDate} onChange={d => setState({...state, endDate: d })} />
                                </>
                            </MuiPickersUtilsProvider>
                            <br/>
                            { error ? <span className="sim-error">{error}</span> : null }
                        </CardContent>
                        <CardActions>
                            <Button disabled={loading || !state.startDate || !state.endDate } onClick={getReport} fullWidth variant="contained" color="primary">Run Report</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <Card>
                        <CardContent>
                            {
                                loading
                                ?
                                <LinearProgress />
                                :
                                null
                            }
                            {
                                dealerStock
                                ?
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        Total SIMs allocated: {dealerStock.length}<br/>
                                        Connected: {connections} {connections > 0 ? `(${(connections / dealerStock.length * 100).toFixed(2)}%)` : `0%`}<br/>
                                        Activated: {activations} {activations > 0 ? `(${(activations / dealerStock.length * 100).toFixed(2)}%)` : `0%`}<br/><br/>
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Card>
                                                <CardHeader
                                                    title="Connected"
                                                    avatar={
                                                        <Avatar>
                                                            <Wifi />
                                                        </Avatar>
                                                    }
                                                />
                                                <CardContent>
                                                    <Chart
                                                        type="radialBar"
                                                        options={chartState.options}
                                                        series={[connections / dealerStock.length * 100]}
                                                        height={350}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Card>
                                                <CardHeader
                                                    title="Activated"
                                                    avatar={
                                                        <Avatar>
                                                            <MonetizationOn />
                                                        </Avatar>
                                                    }
                                                />
                                                <CardContent>
                                                    <Chart
                                                        type="radialBar"
                                                        options={chartState.options}
                                                        series={[activations / dealerStock.length * 100]}
                                                        height={350}
                                                    />
                                                </CardContent>
                                            </Card>
                                            
                                        </Grid>
                                        
                                    </Grid>
                                    
                                </div>
                                :
                                null
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
