import React, { useContext, useEffect, useState } from "react";
import { db, fieldValue, storage, functions } from "../firebase";
import { useAuth } from "./AuthContext";
import Papa from "papaparse";
import {
  mtnDefaultSchema,
  telkomDefaultSchema,
  vcDefaultSchema,
  helloDefaultSchema
} from "../components/shared/constants";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export default function DatabaseProvider({ children, companyId }) {
  const companyRef = db.collection("companies").doc(companyId);
  const { currentUser } = useAuth();

  function GetDealersOfLevel(level) {
    const [dealers, setDealers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("dealers")
        .where("rank", "==", level)
        .onSnapshot(snapshot => {
          const dealerList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            value: doc.id,
            label: doc.data().dealerName
          }));
          setDealers(dealerList);
        });
      return unsubscribe;
    }, [level]);
    return dealers;
  }

  function GetLatestNotifs() {
    const [notifs, setNotifs] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("notifications")
        .orderBy("createdAt", "desc")
        .limit(5)
        .onSnapshot(snapshot => {
          const notifList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setNotifs(notifList);
        });
      return unsubscribe;
    }, []);
    return notifs;
  }

  function GetAllNotifs() {
    const [notifs, setNotifs] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("notifications")
        .orderBy("createdAt", "desc")
        .limit(200)
        .onSnapshot(snapshot => {
          const notifList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setNotifs(notifList);
        });
      return unsubscribe;
    }, []);
    return notifs;
  }

  function GetMtnStock() {
    const [sims, setSims] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("mtnStock2")
        .onSnapshot(snapshot => {
          const simList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSims(simList);
        });
      return unsubscribe;
    }, []);
    return sims;
  }

  function GetConnectionReports(network) {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("connectionReportRequests")
        .orderBy("requestedOn", "desc")
        .onSnapshot(snapshot => {
          const reportList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(reportList);
        });
      return unsubscribe;
    }, [network]);
    return reports;
  }

  function GetConnectionsDealerReport(requestId) {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("connectionReports")
        .where("requestId", "==", requestId)
        .orderBy("connectionTotal", "desc")
        .onSnapshot(snapshot => {
          const reportList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(reportList);
        });
      return unsubscribe;
    }, [requestId]);
    return reports;
  }

  function GetActivationsDealerReport(requestId) {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("activationReports")
        .where("requestId", "==", requestId)
        .orderBy("activationTotal", "desc")
        .onSnapshot(snapshot => {
          const reportList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(reportList);
        });
      return unsubscribe;
    }, [requestId]);
    return reports;
  }

  function GetLevels() {
    const [levels, setLevels] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("dealerLevels")
        .orderBy("level")
        .onSnapshot(snapshot => {
          const levelList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().levelName,
            value: doc.data().level
          }));
          setLevels(levelList);
        });
      return unsubscribe;
    }, []);
    return levels;
  }

  function GetLevelsBelow(level) {
    const [levels, setLevels] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("dealerLevels")
        .where("level", ">", level)
        .orderBy("level")
        .onSnapshot(snapshot => {
          const levelList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().levelName} - (${doc.data().level})`,
            value: { ...doc.data() }
          }));
          setLevels(levelList);
        });
      return unsubscribe;
    }, [level]);
    return levels;
  }

  async function getCurrentUserData(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get()
      .then(doc => {
        return doc.data();
      });
  }

  async function getDealersBelowRank(rank) {
    let dealers = [];
    await db
      .collection("dealers")
      .where("rank", ">", rank)
      .orderBy("rank")
      .get()
      .then(snapshot => {
        const dealerList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          label: `${doc.data().dealerName} - (${doc.data().rank})`,
          value: { ...doc.data() }
        }));
        dealers = dealerList;
      });
    return dealers;
  }

  async function getDealersAboveRank(rank) {
    let dealers = [];
    await db
      .collection("dealers")
      .where("rank", "<", rank)
      .orderBy("rank")
      .get()
      .then(snapshot => {
        const dealerList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          label: `${doc.data().dealerName} - (${doc.data().rank})`,
          value: { ...doc.data() }
        }));
        dealers = dealerList;
      });
    return dealers;
  }

  async function getChildDealers(uid) {
    let dealers = [];
    await db
      .collection("dealers")
      .where("parentDealer", "==", uid)
      .get()
      .then(snapshot => {
        const dealerList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          label: `${doc.data().dealerName} - (${doc.data().rank})`,
          value: { ...doc.data() }
        }));
        dealers = dealerList;
      });
    return dealers;
  }

  // TODO: Remember to set the security rules so that only admin custom claims owners can use this:
  function GetAllUsers() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db.collection("users").onSnapshot(snapshot => {
        const userList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          label: `${doc.data().contactName} ${doc.data().contactSurname} - (${
            doc.data().email
          })`,
          value: { ...doc.data() }
        }));
        setUsers(userList);
      });
      return unsubscribe;
    }, []);
    return users;
  }

  // ---------------------------------------<<<<<<<<<  SAVING DATA >>>>>>>>>>>------------------------------------------

  async function submitBatches(simsArray, network, simId, invoice) {
    return new Promise(async (resolve, reject) => {
      let counter = 0;
      await simsArray.data.forEach(async a => {
        const batch = db.batch();
        await a.forEach(sim => {
          const dbRef = companyRef.collection(network).doc(sim[simId]);
          if (invoice !== null) {
            batch.set(
              dbRef,
              {
                ...sim,
                invoice: invoice
              },
              { merge: true }
            );
          } else {
            batch.set(
              dbRef,
              {
                ...sim
              },
              { merge: true }
            );
          }
          counter++;
        });
        await batch.commit();
        setTimeout(1200);
      });
      resolve(counter);
    });
  }

  async function submitOne(simsArray, network, simId, invoice) {
    const batch = db.batch();
    console.log(simsArray);
    return new Promise(async (resolve, reject) => {
      let counter = 0;
      await simsArray.data.forEach(async sim => {
        const dbRef = companyRef.collection(network).doc(sim[simId]);
        if (invoice !== null) {
          batch.set(
            dbRef,
            {
              ...sim,
              invoice: invoice
            },
            { merge: true }
          );
        } else {
          batch.set(
            dbRef,
            {
              ...sim
            },
            { merge: true }
          );
        }
        counter++;
      });
      await batch.commit();
      resolve(counter);
    });
  }

  async function createConnectionReport(month, year, network) {
    return await companyRef.collection("connectionReportRequests").add({
      month,
      year,
      network,
      companyId,
      requestedOn: new Date(),
      requestComplete: false
    });
  }

  async function createActivationReport(month, year, network) {
    return await companyRef.collection("activationReportRequests").add({
      month,
      year,
      network,
      companyId,
      requestedOn: new Date(),
      requestComplete: false
    });
  }

  async function createLevel(data) {
    const level = data.level;
    return new Promise(async (resolve, reject) => {
      await companyRef
        .collection("dealerLevels")
        .where("level", "==", level)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            companyRef
              .collection("dealerLevels")
              .add({
                ...data
              })
              .then(() => resolve());
          } else {
            reject(`Level ${level} Already Exists`);
          }
        });
    });
  }

  function makeAdminRequest(userId, adminBool) {
    return companyRef.collection("adminRequests").doc(userId).set({
      admin: adminBool
    });
  }

  async function changeLevel(dealerId, newLevel) {
    await companyRef.collection("dealers").doc(dealerId).update({
      rank: newLevel
    });
    return await db.collection("users").doc(dealerId).update({
      rank: newLevel
    });
  }

  async function updateDealer(dealerId, data) {
    // Check if email can be updated
    if (data.email) {
      const updateDealerAccess = functions.httpsCallable(
        "changeUserAccessFromFrontEnd"
      );
      try {
        await updateDealerAccess({
          email: data.email,
          uid: dealerId
        });
      } catch (err) {
        window.alert(err.message);
      }
    }

    await db
      .collection("dealers")
      .doc(dealerId)
      .update({
        ...data
      });

    await db
      .collection("users")
      .doc(dealerId)
      .update({
        ...data
      });
  }

  // ----------------------- Dealer View Reports ------------------------------

  function getSubDealerDetails(subId) {
    return db
      .collection("users")
      .doc(subId)
      .get()
      .then(doc => {
        return doc.data();
      });
  }

  // ----------------------- Dynamic Company Stuff ------------------------------

  function GetCompanyName() {
    const [companyName, setCompanyName] = useState("");

    useEffect(() => {
      const unsubscribe = companyRef.get().then(doc => {
        const coName = doc.data().name;
        setCompanyName(coName);
      });
      return unsubscribe;
    }, []);
    return companyName;
  }

  // ------------------------ Allocation Safety Check -----------------------

  async function CheckIfBoxExists(network, idName, boxId) {
    let result = false;

    const boxRef = db.collection(`${network}_${idName}`).doc(boxId);

    const boxDoc = await boxRef.get();
    if (!boxDoc.exists) {
      throw {
        message: `No SIMS found with Box/Brick Number: ${boxId}`
      };
    }
    const boxData = boxDoc.data();

    // If admin check if there are already agents allocated to this box
    if (currentUser.rank === 1) {
      if (boxData.allocationKey === undefined) result = true;
      else result = false;
    } else result = true;
    return result;
  }

  async function checkIfBoxMine(network, idName, boxId) {
    if (currentUser.rank === 1) return true;
    const boxDoc = await db.collection(`${network}_${idName}`).doc(boxId).get();
    const box = boxDoc.data();

    if (box.allocationKey === currentUser.allocationKey) return true;
    else return false;
  }

  // ------------------ Dealer Attachments --------------------

  async function attachFileToDealer(dealerId, file) {
    return await db
      .collection("dealers")
      .doc(dealerId)
      .collection("attachments")
      .add({
        ...file
      });
  }

  async function deleteDealerAttachment(dealerId, fileId) {
    return await db
      .collection("dealers")
      .doc(dealerId)
      .collection("attachments")
      .doc(fileId)
      .delete();
  }

  function GetDealerFiles(dealerId) {
    const [files, setFiles] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("dealers")
        .doc(dealerId)
        .collection("attachments")
        .onSnapshot(snapshot => {
          const fileList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setFiles(fileList);
        });
      return unsubscribe;
    }, [dealerId]);
    return files;
  }

  // -------------------- RICA ---------------------

  function updateRicaSettings(data) {
    return companyRef
      .collection("ricaSettings")
      .doc("rica")
      .set({
        ...data
      });
  }

  function getRicaSettings() {
    return companyRef
      .collection("ricaSettings")
      .doc("rica")
      .get()
      .then(doc => {
        if (!doc.exists) {
          return { groupName: "", Agent: "", password: "" };
        }
        return doc.data();
      });
  }

  async function getDealerName(uid) {
    return await companyRef
      .collection("dealers")
      .doc(uid)
      .get()
      .then(doc => {
        return doc.data().dealerName;
      });
  }

  async function getDealerEfficiency(dealerId, dealerLevel, data) {
    return new Promise(async (res, rej) => {
      const dealerStock = [];
      await companyRef
        .collection(data.network)
        .where("allocatedTo", "array-contains", dealerId)
        .where(`${dealerLevel}AllocDate`, ">", data.startDate)
        .where(`${dealerLevel}AllocDate`, "<", data.endDate)
        .get()
        .then(snap => {
          if (!snap.empty) {
            snap.docs.forEach(doc => {
              dealerStock.push({
                ...doc.data(),
                id: doc.id
              });
            });
          } else {
            throw {
              message: "No Stock Found"
            };
          }
        });
      res(dealerStock);
    });
  }

  function GetDrilldownSims(network, dealerId, year, month, state, type) {
    const [sims, setSims] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection(network)
        .where("allocatedTo", "array-contains", dealerId)
        .where(state, "==", true)
        .where(`${type}Year`, "==", year)
        .where(`${type}Month`, "==", month)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSims(data);
        });
      return unsubscribe;
    }, [network, dealerId, year, month, state, type]);
    return sims;
  }

  async function getSimDetail(network, iccid) {
    const simRef = companyRef.collection(network).doc(iccid);

    const sim = await simRef.get().then(doc => {
      return {
        ...doc.data(),
        id: doc.id
      };
    });

    for (let i = 0; i < 10; i++) {
      if (sim[i]) {
        await companyRef
          .collection("dealers")
          .doc(sim[i])
          .get()
          .then(doc => {
            sim[`${i}Data`] = doc.data();
          });
      }
    }

    return sim;
  }

  // IWAN ONLY FUNCTIONS

  async function fixParentAllocation(network, dealer) {
    return await companyRef
      .collection(network)
      .where("allocatedTo", "array-contains", dealer.id)
      .get()
      .then(async snap => {
        if (!snap.empty) {
          const simArray = snap.docs.map(sim => ({
            ...sim.data(),
            id: sim.id
          }));
          const batch = await createBatches(simArray);
          console.log(batch);
          if (batch.nested) {
            console.log(batch.data.length);
            for (let i = 0; i < batch.data.length; i++) {
              sleep(4000);
              await singleBatchUpdateSimParents(batch.data[i], network, dealer);
            }
          } else {
            await singleBatchUpdateSimParents(batch.data, network, dealer);
          }
        }
      })
      .then(async () => {
        await companyRef
          .collection("dealers")
          .doc(dealer.id)
          .update({
            [`${network}FixDone`]: false
          });
      });
  }

  async function singleBatchUpdateSimParents(simArray, network, dealer) {
    const batch = db.batch();
    return new Promise(async (resolve, reject) => {
      const date = new Date();
      await simArray.forEach(async sim => {
        const simDbRef = companyRef.collection(network).doc(sim.id);
        let allocArray = sim.allocatedTo;
        allocArray.push(dealer.parentDealer);
        batch.update(simDbRef, {
          allocatedTo: allocArray,
          lastAllocationDate: date
        });
      });
      await batch.commit();
      resolve();
    });
  }

  async function delay() {
    return new Promise(res => {
      setTimeout(() => {
        console.log("Delay");
      }, 4000);
      res();
    });
  }

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  async function iwanActivationReport(year, month, network) {
    return new Promise(async (res, rej) => {
      const dealers = await companyRef
        .collection("dealers")
        .orderBy("rank", "asc")
        .get()
        .then(async snap => {
          console.log(snap.docs.length);
          return snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            activations: []
          }));
        });

      let total = 0;
      const activationsArray = await companyRef
        .collection(network)
        .where("activated", "==", true)
        .where("activationYear", "==", year)
        .where("activationMonth", "==", month)
        .get()
        .then(async snap => {
          total = snap.docs.length;
          return snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
          }));
        });
      console.log(activationsArray.length);
      // Build a new array where we build the activations array level by level and also by unallocated last

      let unallocated = 0;
      let countedArray = [];
      activationsArray.map(sim => {
        // Find the dealer in the dealer array and add activations
        if (sim.allocationKey && !countedArray.includes(sim.id)) {
          sim.allocationKey.map(d => {
            search(d, dealers);
          });
        }
        if (!sim.allocationKey) {
          unallocated++;
        }
        countedArray.push(sim.id);
      });

      function search(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].id === nameKey) {
            return myArray[i].actCount
              ? myArray[i].actCount++
              : (myArray[i].actCount = 1);
          }
        }
      }

      // Building a nested child parent array
      const flat = dealers;
      // Create root for top-level node(s)
      const root = [];
      dealers.forEach(node => {
        // No parentId means top level
        if (!node.parentDealer) return root.push(node);

        // Insert node as child of parent in flat array
        const parentIndex = flat.findIndex(el => el.id === node.parentDealer);
        if (!flat[parentIndex].children) {
          return (flat[parentIndex].children = [node]);
        }

        flat[parentIndex].children.push(node);
      });

      console.log(root);

      const reportResult = {
        dealers: root,
        unallocated,
        total,
        sims: activationsArray
      };
      res(reportResult);
    });
  }

  async function iwanConnectionReport(year, month, network) {
    return new Promise(async (res, rej) => {
      const dealers = await companyRef
        .collection("dealers")
        .orderBy("rank", "asc")
        .get()
        .then(async snap => {
          console.log(snap.docs.length);
          return snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            connections: []
          }));
        });

      let total = 0;
      const connectionsArray = await companyRef
        .collection(network)
        .where("connected", "==", true)
        .where("connectionYear", "==", year)
        .where("connectionMonth", "==", month)
        .get()
        .then(async snap => {
          total = snap.docs.length;
          return snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
          }));
        });
      console.log(connectionsArray.length);
      // Build a new array where we build the activations array level by level and also by unallocated last

      let unallocated = 0;
      let countedArray = [];
      connectionsArray.map(sim => {
        // Find the dealer in the dealer array and add activations
        if (sim.allocationKey && !countedArray.includes(sim.id)) {
          sim.allocationKey.map(d => {
            search(d, dealers);
          });
        }
        if (!sim.allocationKey) {
          unallocated++;
        }
        countedArray.push(sim.id);
      });

      function search(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].id === nameKey) {
            return myArray[i].conCount
              ? myArray[i].conCount++
              : (myArray[i].conCount = 1);
          }
        }
      }
      // Building a nested child parent array
      const flat = dealers;
      // Create root for top-level node(s)
      const root = [];
      dealers.forEach(node => {
        // No parentId means top level
        if (!node.parentDealer) return root.push(node);

        // Insert node as child of parent in flat array
        const parentIndex = flat.findIndex(el => el.id === node.parentDealer);
        if (!flat[parentIndex].children) {
          return (flat[parentIndex].children = [node]);
        }

        flat[parentIndex].children.push(node);
      });

      const reportResult = {
        dealers: root,
        unallocated,
        total,
        sims: connectionsArray
      };
      res(reportResult);
    });
  }

  async function checkMissingAllocated(id, network) {
    return await companyRef
      .collection(network)
      .where("activated", "==", true)

      .where("allocatedTo", "not-in", [id])
      .get()
      .then(snap => {
        console.log(snap.docs.length);
      });
  }

  async function getParentChain(id, rank) {
    return new Promise(async (res, rej) => {
      let parentArray = [];
      let currentId = id;
      for (let i = rank; i > 1; i--) {
        console.log(i);
        if (currentId !== null) {
          const parentId = await getParent(currentId);
          if (parentId !== null) {
            parentArray.push(parentId);
          }
          currentId = parentId;
        }
      }
      var filtered = parentArray.filter(function (el) {
        return el != null;
      });
      console.log(filtered);
      res([...parentArray, id]);
    });
  }

  const getParent = async id => {
    console.log("Getting Parent...");
    return await companyRef
      .collection("dealers")
      .doc(id)
      .get()
      .then(doc => {
        return doc.data().parentDealer;
      });
  };

  async function fixSimAllocation(network, id, newAlloc) {
    return await companyRef.collection(network).doc(id).update({
      newAllocation: newAlloc
    });
  }

  async function addAllocationKey(dealerId, keyArray) {
    return await companyRef.collection("dealers").doc(dealerId).update({
      allocationKey: keyArray
    });
  }

  async function fixNoKeySims(network, rank, dealer) {
    return new Promise(async (res, rej) => {
      if (!dealer.allocationKey) {
        rej("No Allocation Key Found");
      }
      const simArray = await companyRef
        .collection(network)
        .where(rank.toString(), "==", dealer.id)
        .get()
        .then(snap => {
          return snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
          }));
        });

      if (simArray.length === 0) {
        res();
      }

      const batch = await createBatches(simArray);
      if (batch.nested) {
        console.log(batch.data.length);
        for (let i = 0; i < batch.data.length; i++) {
          sleep(2000);
          console.log("Submitting Nested Batch");
          await batchAddSimKey(batch.data[i], network, dealer.allocationKey);
        }
      } else {
        console.log("Submitting Single Batch");
        await batchAddSimKey(batch.data, network, dealer.allocationKey);
      }
      res();
    });
  }

  async function batchAddSimKey(simArray, network, allocationKey) {
    const batch = db.batch();
    return new Promise(async (resolve, reject) => {
      const date = new Date();
      await simArray.forEach(async sim => {
        if (!sim.allocationKey) {
          const simDbRef = companyRef.collection(network).doc(sim.id);
          batch.update(simDbRef, {
            allocationKey,
            lastAllocationDate: date
          });
        } else {
          console.log("Sim Already Keyed");
        }
      });
      await batch.commit();
      resolve();
    });
  }

  async function indicateAddedKeys(dealerId) {
    return await companyRef.collection("dealers").doc(dealerId).update({
      vKeyCheck: true
    });
  }

  async function checkIfJsonExists(network, year, month) {
    return await companyRef
      .collection("jsonReports")
      .doc(`${network}${month}${year}`)
      .get()
      .then(doc => {
        if (!doc.exists) {
          return null;
        } else {
          return doc.data().url;
        }
      });
  }

  async function checkIfConnectionsExist(network, year, month) {
    return await companyRef
      .collection("jsonConnections")
      .doc(`${network}${month}${year}`)
      .get()
      .then(doc => {
        if (!doc.exists) {
          return null;
        } else {
          return doc.data().url;
        }
      });
  }

  async function uploadNewReport(network, year, month, data) {
    // upload the json to firebase storage
    const fileName = `${network}${year}${month}`;
    const storageRef = storage.ref("jsonreports").child(fileName);
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: "application/json" });
    console.log(blob);
    const uploadTask = storageRef.put(blob);
    uploadTask.on(
      "state_changed",
      snapshot => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload Progress: ", upProgress);
      },
      error => {
        console.log("Error During JSON Upload");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async url => {
          await companyRef
            .collection("jsonReports")
            .doc(`${network}${month}${year}`)
            .set({
              network,
              year,
              month,
              url
            });
        });
      }
    );
  }

  async function uploadNewConnectionReport(network, year, month, data) {
    // upload the json to firebase storage
    const fileName = `${network}${year}${month}`;
    const storageRef = storage.ref("jsonconnections").child(fileName);
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: "application/json" });
    console.log(blob);
    const uploadTask = storageRef.put(blob);
    uploadTask.on(
      "state_changed",
      snapshot => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload Progress: ", upProgress);
      },
      error => {
        console.log("Error During JSON Upload");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async url => {
          await companyRef
            .collection("jsonConnections")
            .doc(`${network}${month}${year}`)
            .set({
              network,
              year,
              month,
              url
            });
        });
      }
    );
  }

  // -------------------------------------------------------- NEW BigQuery Way ------------------------------------------------------------------------------------------

  async function importStock(network, invoice, data) {
    return new Promise(async (res, rej) => {
      let records = [];
      data.forEach(r => {
        if (network === "mtn") {
          const { kit, kit_sim, kit_box, kit_brick, supplier_id } = r;
          const row = supplier_id
            ? {
                kit,
                kit_sim,
                kit_box,
                kit_brick,
                invoice,
                ...mtnDefaultSchema,
                supplier_id
              }
            : {
                kit,
                kit_sim,
                kit_box,
                kit_brick,
                invoice,
                ...mtnDefaultSchema
              };
          records.push(row);
        } else if (network === "vodacom") {
          const { serial_number, ref_box_number, carton_id, supplier_id } = r;
          const row = supplier_id
            ? {
                serial_number,
                ref_box_number,
                carton_id,
                ...vcDefaultSchema,
                invoice,
                supplier_id
              }
            : {
                serial_number,
                ref_box_number,
                carton_id,
                ...vcDefaultSchema,
                invoice
              };
          records.push(row);
        } else if (network === "telkom") {
          const { sim, box, supplier_id } = r;
          const row = supplier_id
            ? {
                sim,
                box,
                invoice,
                ...telkomDefaultSchema,
                supplier_id
              }
            : {
                sim,
                box,
                invoice,
                ...telkomDefaultSchema
              };
          records.push(row);
        } else if (network === "megatel") {
          const { sim, box, supplier_id } = r;
          const row = supplier_id
            ? {
                sim,
                box,
                invoice,
                ...telkomDefaultSchema,
                supplier_id
              }
            : {
                sim,
                box,
                invoice,
                ...telkomDefaultSchema
              };
          records.push(row);
        } else if (network === "hello") {
          const { SerialNo, InnerBoxNo, OuterBoxNo, supplier_id } = r;
          const row = supplier_id
            ? {
                SerialNo,
                InnerBoxNo,
                OuterBoxNo,
                invoice,
                supplier_id
              }
            : {
                SerialNo,
                InnerBoxNo,
                OuterBoxNo,
                invoice
              };
          records.push(row);
        } else if (network === "dash") {
          const { SerialNo, InnerBoxNo, OuterBoxNo, supplier_id } = r;
          const row = supplier_id
            ? {
                SerialNo,
                InnerBoxNo,
                OuterBoxNo,
                invoice,
                supplier_id
              }
            : {
                SerialNo,
                InnerBoxNo,
                OuterBoxNo,
                invoice
              };
          records.push(row);
        } else if (network === "cellc") {
          const { SerialNo, InnerBoxNo, OuterBoxNo, supplier_id } = r;
          const row = supplier_id
            ? {
                SerialNo,
                InnerBoxNo,
                OuterBoxNo,
                invoice,
                supplier_id
              }
            : {
                SerialNo,
                InnerBoxNo,
                OuterBoxNo,
                invoice
              };
          records.push(row);
        } else {
          throw {
            message: "Invalid Network (1653)"
          };
        }
      });

      // Put An array of boxes and bricks so users can allocate, and a reference to the invoice (for admin allocate)
      let boxes = null;
      let bricks = null;
      if (network === "mtn") {
        boxes = records
          .filter((v, i, a) => a.findIndex(t => t.kit_box === v.kit_box) === i)
          .map(b => b.kit_box);
        bricks = records
          .filter(
            (v, i, a) => a.findIndex(t => t.kit_brick === v.kit_brick) === i
          )
          .map(b => b.kit_brick);
      } else if (network === "vodacom") {
        boxes = records
          .filter(
            (v, i, a) => a.findIndex(t => t.carton_id === v.carton_id) === i
          )
          .map(b => b.carton_id);
        bricks = records
          .filter(
            (v, i, a) =>
              a.findIndex(t => t.ref_box_number === v.ref_box_number) === i
          )
          .map(b => b.ref_box_number);
      } else if (network === "telkom") {
        boxes = records
          .filter((v, i, a) => a.findIndex(t => t.box === v.box) === i)
          .map(b => b.box);
      } else if (network === "megatel") {
        boxes = records
          .filter((v, i, a) => a.findIndex(t => t.box === v.box) === i)
          .map(b => b.box);
      } else if (network === "hello") {
        boxes = records
          .filter(
            (v, i, a) => a.findIndex(t => t.OuterBoxNo === v.OuterBoxNo) === i
          )
          .map(b => b.OuterBoxNo);
        bricks = records
          .filter(
            (v, i, a) => a.findIndex(t => t.InnerBoxNo === v.InnerBoxNo) === i
          )
          .map(b => b.InnerBoxNo);
      } else if (network === "dash") {
        boxes = records
          .filter(
            (v, i, a) => a.findIndex(t => t.OuterBoxNo === v.OuterBoxNo) === i
          )
          .map(b => b.OuterBoxNo);
        bricks = records
          .filter(
            (v, i, a) => a.findIndex(t => t.InnerBoxNo === v.InnerBoxNo) === i
          )
          .map(b => b.InnerBoxNo);
      } else if (network === "cellc") {
        boxes = records
          .filter(
            (v, i, a) => a.findIndex(t => t.OuterBoxNo === v.OuterBoxNo) === i
          )
          .map(b => b.OuterBoxNo);
        bricks = records
          .filter(
            (v, i, a) => a.findIndex(t => t.InnerBoxNo === v.InnerBoxNo) === i
          )
          .map(b => b.InnerBoxNo);
      }
      console.log("Boxes Here: ", boxes);

      // Send the data to Firebase storage which will trigger the BigQuery Functions
      await uploadCsv(records, `${network}_stock`);
      await addRootInvoice(network, invoice);
      await addRootBoxes(network, boxes, invoice);
      if (bricks) await addRootBricks(network, bricks, invoice);
      res();
    });
  }

  async function importActivations(data, network, year, month) {
    let records = [];
    data.forEach(r => {
      if (network === "mtn") {
        const row = {
          sim: r.sim,
          serial_number: r.kit,
          msisdn: r.msisdn,
          activationYear: year,
          activationMonth: month
        };
        records.push(row);
      } else if (network === "vodacom") {
        const row = {
          serial_number: r.serial_number,
          msisdn: r.msisdn,
          connectionYear: year,
          connectionMonth: month
        };
        records.push(row);
      } else if (network === "telkom") {
        const row = {
          connectionYear: year,
          connectionMonth: month,
          sim: r.sim,
          msisdn: r.msisdn
        };
        records.push(row);
      }
    });
    await uploadCsv(records, `${network}_activations`);
    return records.length;
  }

  async function importFinalConnections(data, network, year, month) {
    let records = [];
    data.forEach(r => {
      if (network === "mtn") {
        const row = {
          connectionYear: year,
          connectionMonth: month,
          sim: r.sim,
          serial_number: r.kit,
          msisdn: r.msisdn
        };
        records.push(row);
      } else if (network === "vodacom") {
        const row = {
          serial_number: r.serial_number,
          msisdn: r.msisdn,
          connectionYear: year,
          connectionMonth: month
        };
        records.push(row);
      } else if (network === "telkom") {
        const row = {
          connectionYear: year,
          connectionMonth: month,
          sim: r.sim,
          msisdn: r.msisdn
        };
        records.push(row);
      }
    });
    await uploadCsv(records, `${network}_connections`);
    return records.length;
  }

  async function importDailyConnections(records, network) {
    return await uploadCsv(records, `${network}_connections`);
  }

  async function createActivationReportRecord(network, year, month, total) {
    return await db
      .collection(`${network}_activations`)
      .doc(`${month}_${year}`)
      .set(
        {
          total,
          year,
          month,
          ran: false,
          timestamp: new Date()
        },
        { merge: true }
      );
  }

  async function createConnectionReportRecord(network, year, month, total) {
    return await db
      .collection(`${network}_connections`)
      .doc(`${month}_${year}`)
      .set(
        {
          total,
          year,
          month,
          ran: false,
          timestamp: new Date()
        },
        { merge: true }
      );
  }

  async function adminActivationRequest(network, year, month) {
    return await db.collection(`${network}ReportRequests`).add({
      year,
      month,
      timestamp: new Date()
    });
  }

  async function adminConnectionRequest(network, year, month) {
    return await db.collection(`${network}ConnectionRequests`).add({
      year,
      month,
      timestamp: new Date()
    });
  }

  function GetActivationReports(network) {
    const [reports, setReports] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection(`${network}ReportRequests`)
        .orderBy("timestamp", "desc")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, [network]);
    return reports;
  }

  function GetConnectionReportRequests(network) {
    const [reports, setReports] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection(`${network}ConnectionRequests`)
        .orderBy("timestamp", "desc")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, [network]);
    return reports;
  }

  async function addRootBoxes(network, data, invoice) {
    // There can be more than 500 so create batches
    const batches = await createBatches(data);
    console.log("BOXES: ", batches);
    if (batches.nested) {
      for (let i = 0; i < batches.data.length; i++) {
        sleep(2000);
        await singleBatchAddRoot(batches.data[i], network, "boxes", invoice);
      }
    } else {
      await singleBatchAddRoot(batches.data, network, "boxes", invoice);
    }
  }

  async function addRootBricks(network, data, invoice) {
    // There can be more than 500 so create batches
    const batches = await createBatches(data);
    console.log("BRICKS: ", batches);
    if (batches.nested) {
      for (let i = 0; i < batches.data.length; i++) {
        sleep(2000);
        await singleBatchAddRoot(batches.data[i], network, "bricks", invoice);
      }
    } else {
      await singleBatchAddRoot(batches.data, network, "bricks", invoice);
    }
  }

  async function addRootInvoice(network, invoice) {
    return await db.collection(`${network}_invoices`).doc(invoice).set({
      timestamp: new Date(),
      addedBy: currentUser.uid,
      invoice,
      network
    });
  }

  async function singleBatchAddRoot(array, network, type, invoice) {
    console.log(type);
    console.log(network);
    console.log(array);
    const batch = db.batch();
    return new Promise(async (resolve, reject) => {
      const date = new Date();
      await array.forEach(async id => {
        if (id) {
          const dbRef = db.collection(`${network}_${type}`).doc(id);
          batch.set(
            dbRef,
            {
              timestamp: date,
              addedBy: currentUser.uid,
              invoice,
              id
            },
            { merge: true }
          );
        }
      });
      await batch.commit();
      resolve();
    });
  }

  async function createDealer(dealerType, data) {
    await db
      .collection("users")
      .where("email", "==", data.email)
      .get()
      .then(snap => {
        if (!snap.empty) {
          return null;
        }
      });

    return companyRef
      .collection("dealerRequests")
      .add({
        dealerType,
        ...data,
        created: new Date(),
        createdBy: currentUser.uid,
        companyId
      })
      .then(doc => {
        return doc;
      })
      .catch(err => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  function GetDealerList(level) {
    const [dealers, setDealers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("dealers")
        .where("rank", "==", level)
        .onSnapshot(snapshot => {
          const dealerList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setDealers(dealerList);
        });
      return unsubscribe;
    }, [level]);
    return dealers;
  }

  function GetAllDealers() {
    const [dealers, setDealers] = useState([]);

    useEffect(() => {
      const unsubscribe = db.collection("dealers").onSnapshot(snapshot => {
        const dealerList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setDealers(dealerList);
      });
      return unsubscribe;
    }, []);
    return dealers;
  }

  // Refactored Allocations
  async function searchInvoice(network, invoiceNr) {
    return await db
      .collection(`${network}_invoices`)
      .doc(invoiceNr)
      .get()
      .then(doc => {
        if (!doc.exists) {
          throw {
            message: "No invoice found with that number."
          };
        } else if (doc.data().allocated) {
          throw {
            message: "This invoice has already been allocated."
          };
        } else {
          return doc.id;
        }
      });
  }

  async function allocateInvoice(network, dealer, invoiceNr) {
    return new Promise(async (res, rej) => {
      await db
        .collection(`${network}_boxes`)
        .where("invoice", "==", invoiceNr)
        .get()
        .then(async snap => {
          if (!snap.empty) {
            const records = snap.docs.map(doc => ({
              ...doc.data(),
              id: doc.id
            }));
            console.log(records);
            const batches = await createBatches(records);
            console.log(batches);
            if (batches.nested) {
              for (let i = 0; i < batches.data.length; i++) {
                sleep(2000);
                await singleBatchUpdate(
                  batches.data[i],
                  network,
                  "boxes",
                  dealer
                );
              }
            } else {
              await singleBatchUpdate(batches.data, network, "boxes", dealer);
            }
          }
        });
      if (network !== "telkom") {
        await db
          .collection(`${network}_bricks`)
          .where("invoice", "==", invoiceNr)
          .get()
          .then(async snap => {
            if (!snap.empty) {
              const records = snap.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
              }));
              const batches = await createBatches(records);
              if (batches.nested) {
                for (let i = 0; i < batches.data.length; i++) {
                  sleep(2000);
                  await singleBatchUpdate(
                    batches.data[i],
                    network,
                    "bricks",
                    dealer
                  );
                }
              } else {
                await singleBatchUpdate(
                  batches.data,
                  network,
                  "bricks",
                  dealer
                );
              }
            }
          });
      }
      await db.collection(`${network}_invoices`).doc(invoiceNr).update({
        allocationKey: dealer.allocationKey,
        lastAllocationDate: new Date(),
        allocatedBy: currentUser.uid,
        allocationArray: dealer.allocationArray,
        allocated: true
      });
      await logAllocation(
        network,
        "invoice",
        invoiceNr,
        dealer.id,
        dealer.allocationKey
      );
      res();
    });
  }

  async function allocateBigQueryBoxBrick(typePlural, allocation, dealer) {
    let counter = 0;

    async function allocateNetwork(network, array) {
      if (array?.length > 0) {
        for (let i = 0; i < array.length; i++) {
          console.log(array[i]);
          await db.collection(`${network}_${typePlural}`).doc(array[i]).update({
            allocationKey: dealer.allocationKey,
            lastAllocationDate: new Date(),
            allocatedBy: currentUser.uid,
            allocationArray: dealer.allocationArray
          });
          counter++;
        }
        // Send the data to Firebase storage which will trigger the BigQuery Functions
        let logPromises = array.map(box => {
          return logAllocation(
            network,
            typePlural,
            box,
            dealer.id,
            dealer.allocationKey
          );
        });
        const records = array.map(box => ({
          box,
          allocationKey: dealer.allocationKey
        }));
        await Promise.all(logPromises);

        const allocationId = await db
          .collection("allocationHistory")
          .add({
            records,
            user: currentUser.uid,
            timestamp: new Date(),
            typePlural,
            network
          })
          .then(doc => {
            return doc.id;
          });

        await companyRef.collection("notifications").add({
          createdAt: new Date(),
          author: {
            contactName: currentUser.contactName
              ? currentUser.contactName
              : currentUser.firstName,
            contactSurname: currentUser.contactSurname
              ? currentUser.contactSurname
              : currentUser.lastName
          },
          type: `Allocated ${array.length} ${typePlural} of ${network}`,
          allocationId
        });
      } else return;
    }

    return new Promise(async (res, rej) => {
      await allocateNetwork("mtn", allocation.mtn);
      await allocateNetwork("cellc", allocation.cellc);
      await allocateNetwork("vodacom", allocation.vodacom);
      await allocateNetwork("telkom", allocation.telkom);
      await allocateNetwork("hello", allocation.hello);
      await allocateNetwork("dash", allocation.dash);
      res(counter);
    });
  }

  async function uploadCsv(records, folder) {
    return new Promise(async (res, rej) => {
      const csv = Papa.unparse(records, { download: true });
      const file = new Blob([csv], { type: "text/csv" });
      var randomstring = Math.random().toString(36).slice(-8);
      const fileName = `${randomstring}.csv`;
      const storageRef = storage.ref(folder).child(fileName);
      const uploadTask = storageRef.put(file);
      await uploadTask.on(
        "state_changed",
        snapshot => {
          var upProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload Progress: ", upProgress);
        },
        error => {
          rej({
            message: `Error uploading file to cloud storage: ${error}`
          });
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async url => {
            console.log(url);
            res(url);
          });
        }
      );
    });
  }

  async function singleBatchUpdate(array, network, type, dealer) {
    const batch = db.batch();
    return new Promise(async (resolve, reject) => {
      const date = new Date();
      await array.forEach(async b => {
        const dbRef = db.collection(`${network}_${type}`).doc(b.id);
        batch.update(
          dbRef,
          {
            allocationKey: dealer.allocationKey,
            lastAllocationDate: new Date(),
            allocatedBy: currentUser.uid,
            allocationArray: dealer.allocationArray
          },
          { merge: true }
        );
      });
      await batch.commit();
      resolve();
    });
  }

  async function createBatches(records) {
    return new Promise(async (resolve, reject) => {
      if (records.length < 500) {
        const smallArray = [];
        await records.forEach(row => {
          smallArray.push(row);
          resolve({ data: smallArray, nested: false });
        });
      } else {
        const chunkedArray = [];
        for (let i = 0; i < records.length; i++) {
          const last = chunkedArray[chunkedArray.length - 1];
          if (!last || last.length === 500) {
            chunkedArray.push([records[i]]);
          } else {
            last.push(records[i]);
          }
        }
        resolve({ data: chunkedArray, nested: true });
      }
    });
  }

  function SubGetChildDealers() {
    const [dealers, setDealers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("dealers")
        .where("parentDealer", "==", currentUser.uid)
        .onSnapshot(snapshot => {
          const dealerList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().dealerName} - ${doc.data().contactName} ${
              doc.data().contactSurname
            } (${doc.data().rank})`
          }));
          setDealers(dealerList);
        });
      return unsubscribe;
    }, []);
    return dealers;
  }

  async function subCreateDealer(data) {
    const formattedPhone = formatPhoneNumber(data.phone);

    return companyRef
      .collection("dealerRequests")
      .add({
        ...data,
        phone: formattedPhone,
        created: new Date(),
        createdBy: currentUser.uid,
        parentDealer: currentUser.uid,
        rank: data.selectedLevel.level,
        companyId
      })
      .then(doc => {
        return doc;
      })
      .catch(err => {
        console.log("Error: ", err.message);
        throw err.message;
      });
  }

  async function findBoxOrBrick(network, type, nr) {
    return await db
      .collection(`${network}_${type}`)
      .doc(nr)
      .get()
      .then(doc => {
        if (!doc.exists) {
          throw {
            message: `No ${type} found with number: ${nr}`
          };
        }
        // if (!doc.data().allocationArray.includes(currentUser.uid)) {
        //   throw {
        //     message: `This box is not allocated to you.`,
        //   };
        // }
        if (doc.data().allocated) {
          throw {
            message: `This box has already been allocated.`
          };
        }
        return doc.id;
      });
  }

  async function allocateBoxes(network, boxes, dealer) {
    return new Promise(async (res, rej) => {
      for (let i = 0; i < boxes.length; i++) {
        await db
          .collection(`${network}_boxes`)
          .doc(boxes[i])
          .update({
            allocationKey: dealer.allocationKey,
            allocationArray: dealer.allocationArray,
            lastAllocationDate: new Date(),
            allocatedBy: currentUser.uid,
            allocated: true
          })
          .catch(err => rej(err));
      }
      res();
    });
  }

  async function updateCard(uid, cardDetails) {
    return await db
      .collection("users")
      .doc(uid)
      .update({
        ...cardDetails
      });
  }

  function GetUserActivations() {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .collection("reports")
        .orderBy("timestamp", "desc")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, []);
    return reports;
  }

  function GetUserConnections() {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .collection("connections")
        .orderBy("timestamp", "desc")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, []);
    return reports;
  }

  function GetUserSubDealerActivations(reportId) {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .collection("reports")
        .doc(reportId)
        .collection("sub_dealers")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, [reportId]);
    return reports;
  }

  function GetUserSubDealerConnections(reportId) {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .collection("connections")
        .doc(reportId)
        .collection("sub_dealers")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, [reportId]);
    return reports;
  }

  async function getDealerReport(reportId) {
    return await db
      .collection("users")
      .doc(currentUser.uid)
      .collection("reports")
      .doc(reportId)
      .get()
      .then(doc => {
        return {
          ...doc.data(),
          id: doc.id
        };
      });
  }

  async function getDealer(dealerId) {
    const dealerRef = db.collection("dealers").doc(dealerId);

    const dealerDoc = await db.collection("dealers").doc(dealerId).get();

    const dealerData = {
      ...dealerDoc.data(),
      id: dealerDoc.id
    };

    const parentDealerDoc = await db
      .collection("dealers")
      .doc(dealerData.parentDealer)
      .get();
    const parentDealerData = parentDealerDoc.data();

    return {
      ...dealerData,
      parentDealerName: `${parentDealerData?.contactName} ${parentDealerData?.contactSurname}`
    };
  }

  async function dealerStockReport(data) {
    return await db.collection("dealer_stock_reports").add({
      dealerId: data.dealerId,
      network: data.network,
      requestedBy: currentUser.uid,
      timestamp: new Date(),
      processed: false
    });
  }

  function GetDealerStockReports(dealerId, network) {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection(`${network}_efficiencies`)
        .where("dealerId", "==", dealerId)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, [dealerId]);
    return reports;
  }

  function GetDealerAgentsEfficiency(dealerId, network) {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection(`${network}_efficiencies`)
        .where("parentDealer", "==", dealerId)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, [dealerId]);
    return reports;
  }

  async function searchStock(data) {
    return await db
      .collection("search_requests")
      .add({
        ...data,
        timestamp: new Date(),
        createdBy: currentUser.uid
      })
      .then(doc => {
        return doc.id;
      });
  }

  async function getSingleSearchResult(searchId) {
    return new Promise(async (res, rej) => {
      // Get the search result document
      const result = await db
        .collection("search_results")
        .where("searchId", "==", searchId)
        .get()
        .then(snap => {
          return {
            ...snap.docs[0].data(),
            id: snap.docs[0].id
          };
        });

      let dealerArray = [];

      const getDealerData = async dealerId => {
        const d = await getDealer(dealerId);
        dealerArray.push(d);
      };

      if (result.results && result.results.length > 0) {
        // Build an array with all the dealer Id's found in this box/brick
        let dealerIdArray = [];
        let conCount = 0;
        let actCount = 0;
        result.results.map(r => {
          if (r.connectionYear !== null) conCount++;
          if (r.activationYear !== null) actCount++;
          if (r.allocationKey) {
            const allocationArray = r.allocationKey.split("-");
            allocationArray.map(a => {
              if (dealerIdArray.indexOf(a) === -1) dealerIdArray.push(a);
            });
          }
        });
        const callArray = dealerIdArray.map(d => {
          return getDealerData(d);
        });
        await Promise.all(callArray);
        res({
          results: result.results,
          conCount,
          actCount,
          dealers: dealerArray
        });
      } else res(null);
    });
  }

  function GetSearchResults(searchId) {
    const [result, setResult] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("search_results")
        .where("searchId", "==", searchId)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setResult(data);
        });
      return unsubscribe;
    }, [searchId]);
    return result;
  }

  async function publishReportToAgents(data, month, year, network, reportType) {
    const promiseArray = data.map(d => {
      db.collection(`${reportType}s`)
        .doc(`${d.id}_${network}_${month}_${year}`)
        .set({
          dealerId: d.id,
          month,
          year,
          network,
          [`${reportType}s`]: d.count
        });
    });
    await Promise.all(promiseArray);
  }

  function GetDealerAgents(dealerId) {
    const [agents, setAgents] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("dealers")
        .where("parentDealer", "==", dealerId)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setAgents(data);
        });
      return unsubscribe;
    }, [dealerId]);
    return agents;
  }

  async function getJsonReport(network, type, reportId) {
    const dbRef =
      type === "connection"
        ? db.collection(`${network}ConnectionRequests`)
        : db.collection(`${network}ReportRequests`);

    return await dbRef
      .doc(reportId)
      .get()
      .then(doc => {
        return doc.data().json;
      });
  }

  async function getDealersOfRank(rank) {
    return await db
      .collection("dealers")
      .where("rank", "==", rank)
      .get()
      .then(snap => {
        return snap.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));
      });
  }

  async function getDealersOfParent(dealerId) {
    return await db
      .collection("dealers")
      .where("parentDealer", "==", dealerId)
      .get()
      .then(snap => {
        return snap.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));
      });
  }

  async function getSingleAllocation(allocationId) {
    return await db
      .collection("allocationHistory")
      .doc(allocationId)
      .get()
      .then(doc => {
        return {
          ...doc.data(),
          id: doc.id
        };
      });
  }

  async function GetRicaLog(dealerId) {
    return await db
      .collection("rica_log")
      .where("uid", "==", dealerId)
      .get()
      .then(snap => {
        return snap.docs.map(doc => ({
          ...doc.data()
        }));
      });
  }

  function GetAllBoxOrBrickAfter(network, type) {
    const [boxes, setBoxes] = useState([]);
    let startDate = new Date("2021-10-1");
    console.log(startDate);
    useEffect(() => {
      const unsubscribe = db
        .collection(`${network}_${type}`)
        .where("lastAllocationDate", ">=", startDate)
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setBoxes(data);
        });
      return unsubscribe;
    }, []);
    return boxes;
  }

  async function iwanGetBrickAllocationKey(user, boxes) {
    let resultArray = [];
    let recordsArray = [];
    let newRecordsArray = [];
    await db
      .collection("allocationHistory")
      .orderBy("timestamp", "desc")
      .get()
      .then(docs => {
        docs.docs.forEach(doc => {
          const d = doc.data();
          if (d.user === user) {
            recordsArray.push({ ...d.records[0] });
          }
        });
      });

    for (let j = 0; j < recordsArray.length; j++) {
      const record = recordsArray[j];
      for (let i = 0; i < boxes.length; i++) {
        const box = boxes[i];
        if (record.box === box) {
          newRecordsArray.push({
            box: box,
            allocationKey: record.allocationKey
          });
        }
      }
    }

    const ids = newRecordsArray.map(o => o.box);
    const filtered = newRecordsArray.filter(
      ({ box }, index) => !ids.includes(box, index + 1)
    );

    console.log(filtered);
    return filtered;
  }

  async function addToFrontOfAllocationKey(data) {
    const { dealerId, frontValue, allocationKey } = data;
    const dealerRef = db.collection("dealers").doc(dealerId);
    const userRef = db.collection("users").doc(dealerId);

    const newKey = `${frontValue}-${allocationKey}`;

    console.log(newKey);

    await dealerRef.update({
      allocationKey: newKey
    });

    await userRef.update({
      allocationKey: newKey
    });

    return;
  }

  function createSupplier(data) {
    return db.collection("suppliers").add({
      ...data,
      createdBy: currentUser.uid,
      created_time: new Date()
    });
  }

  const formatPhoneNumber = number => {
    let formattedPhoneNumber = number;

    formattedPhoneNumber = formattedPhoneNumber.replace(/ /g, "");

    if (formattedPhoneNumber.startsWith("0")) {
      formattedPhoneNumber = `+27${formattedPhoneNumber.slice(1, 10)}`;
    }

    if (formattedPhoneNumber.startsWith("+270")) {
      formattedPhoneNumber = `+27${formattedPhoneNumber.slice(4, 13)}`;
    }

    if (formattedPhoneNumber.length === 9) {
      formattedPhoneNumber = `+27${formattedPhoneNumber}`;
    }

    return formattedPhoneNumber;
  };

  function GetSuppliers() {
    const [suppliers, setSuppliers] = useState([]);
    useEffect(() => {
      const unsubscribe = db.collection("suppliers").onSnapshot(snapshot => {
        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          value: doc.id,
          label: doc.data().supplierName
        }));
        setSuppliers(data);
      });
      return unsubscribe;
    }, []);
    return suppliers;
  }

  function logAllocation(network, type, entityNo, dealer, allocationKey) {
    return db.collection(`${network}_${type}_allocations`).add({
      dealer,
      allocationKey,
      allocatedBy: currentUser.uid,
      allocationDate: new Date(),
      entityNo
    });
  }

  function addUploadInstance(uid, fileName) {
    return new Promise((resolve, reject) => {
      db.collection("upload_instances")
        .add({
          time: new Date(),
          uid: uid,
          fileName: fileName
        })
        .then(() => {
          resolve();
        });
    });
  }

  function GetLookerReports() {
    const [reports, setReports] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("looker_reports")
        .onSnapshot(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setReports(data);
        });
      return unsubscribe;
    }, []);
    return reports;
  }

  const value = {
    createDealer,
    GetDealerList,
    GetLatestNotifs,
    GetAllNotifs,
    GetMtnStock,
    createBatches,
    submitBatches,
    submitOne,
    GetAllDealers,
    createConnectionReport,
    GetConnectionReports,
    GetConnectionsDealerReport,
    createActivationReport,
    GetActivationReports,
    GetActivationsDealerReport,
    createLevel,
    GetLevels,
    GetLevelsBelow,
    getCurrentUserData,
    getDealersBelowRank,
    getDealersAboveRank,
    getChildDealers,
    GetAllUsers,
    makeAdminRequest,
    GetDealersOfLevel,
    changeLevel,
    subCreateDealer,
    SubGetChildDealers,
    getSubDealerDetails,
    GetCompanyName,
    CheckIfBoxExists,
    GetDealerFiles,
    deleteDealerAttachment,
    attachFileToDealer,
    updateRicaSettings,
    getRicaSettings,
    updateDealer,
    getDealerName,
    getDealerEfficiency,
    GetDrilldownSims,
    getSimDetail,
    fixParentAllocation,
    iwanActivationReport,
    checkMissingAllocated,
    getParentChain,
    fixSimAllocation,
    addAllocationKey,
    fixNoKeySims,
    indicateAddedKeys,
    checkIfJsonExists,
    uploadNewReport,
    iwanConnectionReport,
    checkIfConnectionsExist,
    uploadNewConnectionReport,
    searchInvoice,
    allocateInvoice,
    findBoxOrBrick,
    allocateBoxes,
    updateCard,
    allocateBigQueryBoxBrick,
    createActivationReportRecord,
    adminActivationRequest,
    adminConnectionRequest,
    GetConnectionReportRequests,
    createConnectionReportRecord,
    GetUserActivations,
    getDealerReport,
    GetUserSubDealerActivations,
    GetUserConnections,
    GetUserSubDealerConnections,
    importDailyConnections,
    importStock,
    importActivations,
    importFinalConnections,
    getDealer,
    dealerStockReport,
    searchStock,
    GetSearchResults,
    getSingleSearchResult,
    GetDealerStockReports,
    publishReportToAgents,
    GetDealerAgentsEfficiency,
    GetDealerAgents,
    getJsonReport,
    getDealersOfRank,
    getDealersOfParent,
    getSingleAllocation,
    GetRicaLog,
    GetAllBoxOrBrickAfter,
    iwanGetBrickAllocationKey,
    formatPhoneNumber,
    checkIfBoxMine,
    addToFrontOfAllocationKey,
    createSupplier,
    GetSuppliers,
    logAllocation,
    addUploadInstance,
    GetLookerReports
  };
  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
