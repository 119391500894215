import React from "react";
import BackLink from "../../shared/BackLink";
import { Link } from "react-router-dom";
import { Button, Container } from "@mui/material";

export default function KCMobileImports() {
  return (
    <div>
      <h1>KCM Imports</h1>
      <BackLink />
      <Container>
        <Link to="/kc-connections">
          <Button color="primary" variant="contained">
            Connections
          </Button>
        </Link>
        &nbsp;
        <Link to="/kc-activations">
          <Button color="primary" variant="contained">
            Activations
          </Button>
        </Link>
        &nbsp;
        <Link to="/kc-churn">
          <Button color="primary" variant="contained">
            Churn
          </Button>
        </Link>
        &nbsp;
        <Link to="/kc-recharges">
          <Button color="primary" variant="contained">
            Recharges
          </Button>
        </Link>
        &nbsp;
        <Link to="/kc-allocations">
          <Button color="primary" variant="contained">
            Allocations
          </Button>
        </Link>
      </Container>
    </div>
  );
}
