import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid
} from "@mui/material";
import { Check, SimCard } from "@mui/icons-material";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import BackLink from "../../shared/BackLink";
import Select from "react-select";
import TypeSim from "./TypeSim";
import { functions } from "../../../firebase";
import { networks, selectFieldProps, simNames } from "../../shared/constants";

export default function AllocateSingleSims() {
  const { userLevel, currentUser } = useAuth();
  const { SubGetChildDealers, GetAllDealers } = useDb();
  const [loading, setLoading] = useState(false);

  const dealers = userLevel <= 1 ? GetAllDealers() : SubGetChildDealers();

  const dealerOptions = dealers.map(d => ({
    value: d,
    label: `${d.contactName} ${d.contactSurname} - (Level ${d.rank})`
  }));

  const [dealer, setDealer] = useState();
  const [simArray, setSimArray] = useState([]);
  const [typeOpen, setTypeOpen] = useState(false);
  const [network, setNetwork] = useState(null);

  const verifySim = async data => {
    const verify = functions.httpsCallable("isMySim");
    const result = await verify({
      sim: data,
      network,
      simId: simNames[network]
    });
    // See if the sim even exists.
    if (result.data.length < 1) {
      window.alert("Sim Not Found");
      return false;
    }
    // If you're a dealer, make sure you are actually allowed to do this.
    if (userLevel > 1) {
      if (result.data[0].allocationKey.includes(currentUser.uid)) return true;
      else return false;
    } else return true;
  };

  const handlePhoneData = async data => {
    setSimArray([...simArray, data]);
  };

  const allocateSimFunction = async () => {
    setLoading(true);

    const allocate = functions.httpsCallable("allocateString");

    allocate({
      network,
      simArray,
      simId: simNames[network],
      allocationKey: dealer.allocationKey
    })
      .then(result => {
        alert("Allocations Complete.");
        setLoading(false);
        setSimArray([]);
      })
      .catch(() => {
        alert("Something went wrong. Please try again.");
      });
  };

  return (
    <div>
      <h1>Allocate Single Sims</h1>
      <BackLink />
      <Select
        options={dealerOptions}
        onChange={val => setDealer(val.value)}
        menuPortalTarget={document.body}
        {...selectFieldProps}
        placeholder={"Select a Dealer"}
        className="sim-select"
      />
      <Select
        options={networks}
        onChange={val => setNetwork(val.value)}
        menuPortalTarget={document.body}
        {...selectFieldProps}
        placeholder={"Select a Network"}
        className="sim-select"
      />
      {dealer && network && (
        <Card>
          <CardHeader
            title="SIMS in Allocation"
            avatar={
              <Avatar>
                <SimCard />
              </Avatar>
            }
          />
          <CardContent>
            <Grid container spacing={1}>
              {simArray &&
                simArray.map(sim => (
                  <Grid key={sim} item xs={12} md={12}>
                    <Card>
                      <CardHeader
                        title={sim}
                        avatar={
                          <Avatar>
                            <SimCard />
                          </Avatar>
                        }
                      />
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              onClick={() => setTypeOpen(true)}
            >
              Enter New Sim No (ICCID)
            </Button>
            {simArray && simArray.length > 0 && (
              <Button
                onClick={() => {
                  allocateSimFunction();
                }}
                disabled={loading}
                color="primary"
                variant="contained"
                startIcon={<Check />}
              >
                {loading ? "Loading..." : "Complete Allocation"}
              </Button>
            )}
          </CardActions>
        </Card>
      )}
      {/* 8927000008115593633, 8927000007268236412 */}
      <TypeSim
        open={typeOpen}
        close={() => setTypeOpen(false)}
        send={handlePhoneData}
        verifySim={verifySim}
        simArray={simArray}
      />
    </div>
  );
}
