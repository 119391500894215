import { Hidden } from "@mui/material";
import React from "react";

export default function SuperDailyConnections({ uid }) {
  var params = {
    "ds0.uid": uid,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);

  return (
    <div>
      <Hidden mdUp>
        {/* Phone Report */}
        <iframe
          width="350"
          height="650"
          src={`https://datastudio.google.com/embed/reporting/2d0d4a18-b03a-4d3f-9491-ad083e61f5eb/page/OjHuC?params=${encodedParams}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
      <Hidden smDown>
        {/* Desktop Report */}
        <iframe
          width="1200"
          height="900"
          src={`https://datastudio.google.com/embed/reporting/b8d9f620-3d43-4ad0-9705-4f2951b95e6d/page/OjHuC?params=${encodedParams}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowfullscreen
        ></iframe>
      </Hidden>
    </div>
  );
}
