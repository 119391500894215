import { Button, Card, CardContent, CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import JsonReportDetail from './JsonReportDetail'

export default function JsonDealerReport() {
    const { network, type, reportId, dealerId } = useParams()
    const history = useHistory()
    const { getJsonReport, getDealersOfParent } = useDb()

    const [report, setReport] = useState()
    const [dealers, setDealers] = useState()

    const firstLoad = async () => {
        const jsonUrl = await getJsonReport(network, type, reportId)
        const r = await fetch(jsonUrl).then(res => {
            return res.json()
        })
        const dealerArray = await getDealersOfParent(dealerId)
        // De-String the allocation Key
        r && r.map(sim => {
            if(sim.allocationKey) {
                const uidArray = sim.allocationKey.split("-")
                sim.uidArray = uidArray
            }
        })

        dealerArray && dealerArray.map(d => {
            let counter = 0
            r && r.map(sim => {
                if(sim.uidArray && sim.uidArray.includes(d.id)) {
                    counter++
                }
            })
            d.count = counter
        })
        console.log(dealerArray)
        setDealers(dealerArray)
        setReport(r)
    }

    if(!report) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>Dealer {type} report</h1>
            <div className="back-link">
                <Button
                    startIcon={<ArrowBack />}
                    variant="contained"
                    color="primary"
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            <Card>
                <CardContent>
                    <JsonReportDetail
                        dealers={dealers}
                        reportId={reportId}
                        network={network}
                        type={type}
                    />
                </CardContent>
            </Card>
        </div>
    )
}
