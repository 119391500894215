import { faBoxes, faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, CardActions, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material'
import { ArrowLeft, Search, SimCard } from '@mui/icons-material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { networks } from '../../shared/constants'
import SearchResult from './SearchResult'

export default function CheckStock() {
    const history = useHistory()
    const { searchStock, getSim } = useDb()
    const [network, setNetwork] = useState(null)
    const [id, setId] = useState('')
    const [boxIdName, setBoxIdName] = useState(null)
    const [brickIdName, setBrickIdName] = useState(null)
    
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [widget, setWidget] = useState(null)
    const [error, setError] = useState(null)

    const search = async (idType) => {
        setLoading(true)
        setOpen(true)
        try {
            const result = await searchStock(network, idType, id)
            
            setWidget(<SearchResult result={result} simOnly={false} />)
            setLoading(false)
        }   
        catch(err) {
            setError(err.message)
            setWidget(<>{ error ? <span className="sim-error">{error}</span> : null }</>)
            setLoading(false)
        }
        
    }

    const handleNetworkChange = (n) => {
        setNetwork(n)
        if(n === 'vodacom') {
            setBoxIdName('carton_id')
            setBrickIdName('ref_box_number')
        }
        if(n === 'mtn') {
            setBoxIdName('kit_box')
            setBrickIdName('kit_brick')
        }
        if(n === 'cellC') {
            setBoxIdName('box')
            setBrickIdName('brick')
        }
        if(n === 'telkom') {
            setBoxIdName('box')
            setBrickIdName('brick')
        }
    }

    const getSimcard = async () => {
        setLoading(true)
        setOpen(true)
        try {
            const result = await getSim(network, id)
            if(result !== null) {
                setWidget(<SearchResult result={result} simOnly={true} />)
                setLoading(false)
            }
        }
        catch(err) {
            setError(err.message)
            setWidget(<>{ error ? <span className="sim-error">{error}</span> : null }</>)
            setLoading(false)
        }
        
    }

    const closeDialog = () => {
        setOpen(false)
        setLoading(false)
        setWidget(null)
    }

    return (
        <div>
            <h1>Check Stock</h1>
            <h2>Search By:</h2>
            <Select
                options={networks}
                onChange={(val) => handleNetworkChange(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select Network ...'}
            />
            <br/>
            {
                network 
                ?
                <Grid container spacing={3}>

                <Grid item xs={12} md={4} lg={4}>
                    <Card>
                        <CardHeader
                            avatar={<Avatar><SimCard /></Avatar>}
                            title={'SIM'}
                            subheader={<TextField variant={'outlined'} fullWidth onChange={(e) => setId(e.target.value)} />}
                        />
                        <CardActions>
                            
                            <Button color="primary" variant="contained" disabled={loading || !id } onClick={getSimcard} color={'primary'} startIcon={<Search />}>Search</Button>
                            
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            avatar={<Avatar><FontAwesomeIcon icon={faBoxOpen} /></Avatar>}
                            title={'Box'}
                            subheader={<TextField variant={'outlined'} fullWidth onChange={(e) => setId(e.target.value)}/>}
                        />
                        <CardActions>
                            <Button color="primary" variant="contained" disabled={loading || !id } onClick={() => search(boxIdName)} color={'primary'} startIcon={<Search />}>Search</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            avatar={<Avatar><FontAwesomeIcon icon={faBoxes} /></Avatar>}
                            title={'Brick'}
                            subheader={<TextField variant={'outlined'} fullWidth onChange={(e) => setId(e.target.value)}/>}
                        />
                        <CardActions>
                            <Button color="primary" variant="contained" disabled={loading || !id } onClick={() => search(brickIdName)} color={'primary'} startIcon={<Search />}>Search</Button>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid>
            : 
            null
            }
            
            <Dialog maxWidth="md" fullWidth open={open} onClose={closeDialog}>
                <DialogContent>
                    {
                        loading 
                        ?
                        <CircularProgress />
                        :
                        <div>
                            { widget ? widget : null }
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color={'secondary'}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
