import React from "react";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import MainMenu from "./MainMenu";
import DatabaseProvider from "../contexts/DatabaseContext";
import DealerMenu from "./DealerMenu";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material";
import PublicRoutes from "./routes/PublicRoutes";
import BlockedRoutes from "./routes/BlockedRoutes";
import { customers } from "../config/customerConfig";

export default function Wrapper() {
  const { currentUser, userLevel } = useAuth();

  const theme = currentUser
    ? customers[currentUser.alias].theme
    : customers.default.theme;
  const logo = currentUser
    ? customers[currentUser.alias].logo
    : customers.default.logo;

  const clientTheme = createTheme(theme);

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={clientTheme}>
          {currentUser && currentUser.uid ? (
            !currentUser.blocked ? (
              userLevel === 1 ? (
                <DatabaseProvider companyId={currentUser.companyId}>
                  <MainMenu logo={logo} />
                </DatabaseProvider>
              ) : (
                <DatabaseProvider companyId={currentUser.companyId}>
                  <DealerMenu logo={logo} />
                </DatabaseProvider>
              )
            ) : (
              <BlockedRoutes />
            )
          ) : (
            <PublicRoutes logo={logo} />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}
