import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import mtn from '../../../img/mtn.png'
import telkom from '../../../img/telkom.png'

export default function DealerReports() {
    return (
        <div>
            <h1>Reports</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <Card>
                        <CardHeader 
                            title={'MTN'}
                            avatar={<FontAwesomeIcon icon={faChartBar}/>}
                        />
                        <CardContent className="sim-card-content">
                            <img src={mtn} width="auto" height="100"/>
                        </CardContent>
                        <CardActions>
                            <Link to='/mtn-daily-connections'><Button color="primary">Daily Connections</Button></Link>
                            <Link to='/mtn-final-connections'><Button color="primary">Final Connections</Button></Link>
                            <Link to='/mtn-activations'><Button color="primary">Activations</Button></Link>
                            
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardHeader 
                            title={'Telkom'}
                            avatar={<FontAwesomeIcon icon={faChartBar}/>}
                        />
                        <CardContent className="sim-card-content">
                            <img src={telkom} width="auto" height="100"/>
                        </CardContent>
                        <CardActions>
                            {/* <Link to='/reports/connections'><Button color="primary">Daily Usage</Button></Link> */}
                            <Link to='/telkom-activations'><Button color="primary">Activations</Button></Link>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
