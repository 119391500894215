import { Button, CircularProgress } from "@mui/material";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDb } from "../../contexts/DatabaseContext";

export default function IwanConsole() {
  const { iwanAddManualDateUpdate } = useDb();

  const boxes = [
    "CJA1698904",
    "CJA1698991",
    "CJA1699017",
    "CJA1699248",
    "CJA1728108",
    "CJA1728985",
    "CJA1728986",
    "CJA1729001",
    "CJA1729002",
    "CJA1729009",
    "CJA1729011",
    "CJA1729017",
    "CJA1729022",
    "CJA1729027",
    "CJA1729028",
    "CJA1729035",
    "CJA1737287",
    "CJA1737305",
    "CJA1737314",
    "CJA1737328",
    "CJA1737335",
    "CJA1737336",
    "CJA1737339",
    "CJA1737341",
    "CJA1737355",
    "CJA1737361",
    "CJA1737362",
    "CJA1737379",
    "CJA1737403",
    "CJA1754894",
    "CJA1754909",
    "CJA1754952",
    "CJA1754954",
    "CJA1778555",
    "CJA1778558",
    "CJA1778559",
    "CJA1778564",
    "CJA1778565",
    "CJA1778567",
    "CJA1778568",
    "CJA1778588",
    "CJA1778594",
    "CJA1778597",
    "CJA1778600",
    "CJA1778611",
    "CJA1778613",
    "CJA1778614",
    "CJA1778617",
    "CJA1778618",
    "CJA1778619",
    "CJA1778620",
    "CJA1778622",
    "CJA1778624",
    "CJA1778627",
    "CJA1778630",
    "CJA1218978",
    "CJA1219013",
    "CJA1242076",
    "CJA1242077",
    "CJA1242110",
    "CJA1242197",
    "CJA1271834",
    "CJA1319519",
    "CJA1319551",
    "CJA1319556",
    "CJA1319562",
    "CJA1357303",
    "CJA1469612",
    "CJA1471388",
    "CJA1471402",
    "CJA1504411",
    "CJA1504412",
    "CJA1504413",
    "CJA1504414",
    "CJA1504533",
    "CJA1559742",
    "CJA1559773",
    "CJA1561985",
    "CJA1561986",
    "CJA1569098",
    "CJA1574107",
    "CJA1577530",
    "CJA1577548",
    "CJA1577556",
    "CJA1577566",
    "CJA1577623",
    "CJA1577643",
    "CJA1577693",
    "CJA1577697",
    "CJA1577768",
    "CJA1577779",
    "CJA1577830",
    "CJA1602009",
    "CJA1602042",
    "CJA1614193",
    "CJA1614202",
    "CJA1614210",
    "CJA1682314",
    "CJA1682324",
    "CJA1682326",
    "CJA1682329",
    "CJA1682347",
    "CJA1682660",
    "CJA1682666",
    "CJA1682667",
    "CJA1682671",
    "CJA1682693",
    "CJA1682705",
    "CJA1682709",
    "CJA1682712",
    "CJA1682719",
    "CJA1682724",
    "CJA1682728",
    "CJA1682736",
    "CJA1682739",
    "CJA1682742",
    "CJA1682767",
    "CJA1682770",
    "CJA1682773",
    "CJA1682867",
    "CJA1682880",
    "CJA1682896",
    "CJA1682904",
    "CJA1682919",
    "CJA1682932",
    "CJA1682934",
    "CJA1682937",
    "CJA1682958",
    "CJA1682961",
    "CJA1682962",
    "CJA1737886",
    "CJA1737942",
    "CJA1737964",
    "CJA1737967",
    "CJA1737969",
    "CJA1737971",
    "CJA1737984",
    "CJA1737985",
    "CJA1737986",
    "CJA1737987",
    "CJA1737991",
    "CJA1737992",
    "CJA1737993",
    "CJA1738021",
    "CJA1738022",
    "CJA1738024",
    "CJA1738039",
    "CJA1738041",
    "CJA1738046",
    "CJA1738048",
    "CJA1738049",
    "CJA1738057",
    "CJA1738058",
    "CJA1738059",
    "CJA1738060",
  ];

  const handleBulkUpdate = async () => {
    try {
        await iwanAddManualDateUpdate("mtn_boxes", boxes)
        window.alert("Update Complete!")
    }
    catch(err) {
        window.alert(err.message)
    }

  };

  return (
    <div onClick={handleBulkUpdate}>
      <Button>Initiate Date Update</Button>
    </div>
  );
}
