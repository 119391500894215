import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React from "react";
import allocateStock from "../../img/allocate-stock.svg";
import manageDealers from "../../img/manage-dealers.svg";
import reports from "../../img/reports.svg";
import networkImport from "../../img/import-network-files.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faTruck,
  faIdCard,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import LatestNotifs from "../notifications/LatestNotifs";
import ImportStock from "../import-stock/ImportStock";
import Reports from "../reports/Reports";
import { useAuth } from "../../contexts/AuthContext";

export default function AdminDashboard() {
  const { currentUser } = useAuth();

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <Card>
            <CardHeader
              title={"Allocate Stock"}
              avatar={<FontAwesomeIcon icon={faTruck} />}
            />
            <CardContent className="sim-card-content">
              <img
                alt="Allocate Stock"
                src={allocateStock}
                width="100%"
                height="100"
              />
            </CardContent>
            <CardActions>
              <Link to={"/allocate-stock/box/boxes"}>
                <Button color="primary">Box</Button>
              </Link>
              <Link to={"/allocate-stock/box/bricks"}>
                <Button color="primary">Brick</Button>
              </Link>
              <Link to={"/allocate-singles"}>
                <Button color="primary">Sim</Button>
              </Link>
              {/* <Link to={'/check'}><Button color="primary">Check</Button></Link> */}
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Card>
            <CardHeader
              title={"Manage Dealers"}
              avatar={<FontAwesomeIcon icon={faIdCard} />}
            />
            <CardContent className="sim-card-content">
              <img
                alt="Manage Dealers"
                src={manageDealers}
                width="100%"
                height="100"
              />
            </CardContent>
            <CardActions>
              <Link to="/manage-dealers">
                <Button color="primary">Dealers</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>

        {currentUser.master_account && (
          <Grid item xs={12} md={8} lg={3}>
            <Card>
              <CardHeader
                title={"Import Network Files"}
                avatar={<FontAwesomeIcon icon={faChartBar} />}
              />
              <CardContent className="sim-card-content">
                <img
                  alt="Import Network Files"
                  src={networkImport}
                  width="100%"
                  height="100"
                />
              </CardContent>
              <CardActions>
                <Link to="/network-imports">
                  <Button color="primary">Network Imports</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} lg={12}>
          <Card>
            <CardHeader
              title={"Recent Activity"}
              avatar={<FontAwesomeIcon icon={faList} />}
            />
            <CardContent>
              <LatestNotifs />
            </CardContent>
            <CardActions>
              <Link to="/all-notifications">
                <Button color="primary" variant="contained">
                  View All
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={3}></Grid>
    </div>
  );
}
