import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ArrowForward } from "@mui/icons-material";
import { authFunc } from "../../firebase";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PhoneLogin() {
  const classes = useStyles();
  const { currentUser, loginWithPhone, formatPhoneNumber, checkPhoneExists } =
    useAuth();
  const [state, setState] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [otpView, setOtpView] = useState(false);

  // New Fireship Method
  const [recaptcha, setRecaptcha] = useState(null);
  const element = useRef(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!recaptcha) {
      const verifier = new authFunc.RecaptchaVerifier(element.current, {
        size: "invisible",
      });
      verifier.verify().then(() => setRecaptcha(verifier));
    }
  });

  async function handleSubmit() {
    // TODO: First Check to see if dealer exists, if not throw
    const formattedPhoneNumber = formatPhoneNumber(state.phone);
    try {
      setError(null);
      setLoading(true);
      const phoneExists = await checkPhoneExists(formattedPhoneNumber);
      if (phoneExists) {
        await loginWithPhone(formattedPhoneNumber, recaptcha).then(
          (confirmation) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            console.log(confirmation);
            setConfirmationResult(confirmation);
            setOtpView(true);
          }
        );
      } else {
        throw {
          message: "Dealer phone number does not exist",
        };
      }
      // history.push('/')
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  async function handleOtpSubmit() {
    setError(null);
    setLoading(true);
    try {
      await confirmationResult.confirm(state.otp).then((result) => {
        // User signed in successfully.
        const user = result.user;
        history.push("/");
        // ...
      });
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div ref={element}></div>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <CssBaseline />
      {!otpView ? (
        <div className={classes.paper}>
          <br />
          <Typography component="h1" variant="h5">
            Sign in with Phone
          </Typography>
          {recaptcha ? (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Phone Number"
                name="phone"
                autoFocus
                type="text"
                onChange={handleChange}
              />
              <br />
              <span style={{ color: "red" }}>{error}</span>
              <br />
              <span style={{ color: "blue" }}>{currentUser?.email}</span>
              <Button
                onClick={handleSubmit}
                disabled={loading}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                endIcon={<ArrowForward />}
              >
                Next
              </Button>
            </>
          ) : (
            <CircularProgress />
          )}
        </div>
      ) : (
        <div>
          <Typography component="h1" variant="h5">
            Enter OTP
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Enter OTP"
            name="otp"
            autoFocus
            onChange={handleChange}
          />
          <br />
          <span style={{ color: "red" }}>{error}</span>
          <br />
          <span style={{ color: "blue" }}>{currentUser?.email}</span>
          <Button
            onClick={handleOtpSubmit}
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </div>
      )}
    </Container>
  );
}
