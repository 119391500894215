import { Card, CardContent } from '@mui/material'
import React from 'react'
import BackLink from '../../shared/BackLink'
import FileUploader from '../../shared/FileUploader'

export default function MtnSimSwapsImport() {
  const getFile = f => {
    console.log(f);
  };

  return (
    <div>
      <h1>MTN Sim Swaps Import</h1>
      <BackLink />
      <Card>
        <CardContent>
          <FileUploader
            sendFile={getFile}
            label="Upload MTN Sim Swaps File"
            storagePath={"mtn_sim_swaps/"}
          />
        </CardContent>
      </Card>
    </div>
  );
}
