import { Button, Card, CardActions, CardContent } from "@mui/material";
import React from "react";
import BackLink from "../../shared/BackLink";
import FileUploader from "../../shared/FileUploader";
import { FileCopy } from "@mui/icons-material";

export default function TelkomSmartCallMonthly() {
  const getFile = (f) => {
    console.log(f);
  };

  return (
    <div>
      <h1>Smartcall: Tekom Monthly Activation</h1>
      <BackLink />
      <Card>
        <CardActions>
          <Button
            color="primary"
            startIcon={<FileCopy />}
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/shekinah-sim.appspot.com/o/scTelkomMonthlyExample.csv?alt=media&token=f8d9313f-b163-4ac0-8b63-380603de85f6"
          >
            Sample File
          </Button>
        </CardActions>
        <CardContent>
          <FileUploader
            sendFile={getFile}
            label="Upload Smartcall Telkom File"
            storagePath={"smartcall_telkom_monthly/"}
          />
        </CardContent>
      </Card>
    </div>
  );
}
