import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'

export default function SimDetail() {
    const { network, iccid } = useParams()
    const { getSimDetail } = useDb()

    const [sim, setSim] = useState(null)

    // Action State
    const [loaded, setLoaded] = useState(false)

    const firstLoad = async () => {
        const s = await getSimDetail(network, iccid)
        console.log(s)
        setSim(s)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>SIM Detail: {iccid}</h1>
            
        </div>
    )
}
