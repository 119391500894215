import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Check, CropSquareSharp, Search } from '@mui/icons-material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

export default function DrilldownSims() {
    const { network, dealerId, year, month, state, type } = useParams()
    const { GetDrilldownSims } = useDb()
    const sims = GetDrilldownSims(network, dealerId, year, month, state, type)
    console.log(sims)
    return (
        <div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ICCID</TableCell>
                            <TableCell>Connected</TableCell>
                            <TableCell>Activated</TableCell>
                            <TableCell align="right">Detail</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sims && sims.map(s => {
                                return (
                                    <TableRow key={s.id}>
                                        <TableCell>{s.id}</TableCell>
                                        <TableCell>{s.connected ? <Check /> : <CropSquareSharp />}</TableCell>
                                        <TableCell>{s.activated ? <Check /> : <CropSquareSharp />}</TableCell>
                                        <TableCell align="right">
                                            <Link to={`/sim/${network}/${s.id}`}>
                                                <Search />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <CSVLink
                data={sims}
                style={{ marginTop: 5, textDecoration: 'none'}}
                filename={`${dealerId} SIMS`}
            >
                <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFileExcel}/>} style={{ backgroundColor: 'green', color: '#fff'}}> Export to CSV</Button>
            </CSVLink>
        </div>
    )
}
